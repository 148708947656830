<script setup>
import { ref, toRefs, watch } from "vue";
const props = defineProps({
  rankingarray: Array,
});
const { rankingarray } = toRefs(props);
const showrankingarray = ref();
// showrankingarray.value = rankingarray.value;
showrankingarray.value = rankingarray.value.map((item) => {
  return { ...item, iconURL: resizeIconURL(item.iconURL) };
});

watch(rankingarray, () => {
  rankingarray.value.forEach((item) => {
    item.iconURL = resizeIconURL(item.iconURL);
  });

  showrankingarray.value = rankingarray.value;
});

function resizeIconURL(iconURL) {
  return iconURL.replace(/iconphoto\.png$/, "iconphoto_400x400.png");
}
</script>

<template>
  <ul>
    <template v-for="(item, index) in showrankingarray" :key="item">
      <!-- <p>{{ item.uid }}</p> -->
      <li>
        <div class="icon"><img :src="item.iconURL" /></div>
        <div v-if="index == 0" class="rank"><img src="img/rank1.png" /></div>
        <div v-else-if="index == 1" class="rank"><img src="img/rank2.png" /></div>
        <div v-else-if="index == 2" class="rank"><img src="img/rank3.png" /></div>
      </li>
    </template>
  </ul>
</template>

<style scoped>
ul {
  display: flex;
  width: 5000px;
}
ul li {
  position: relative;
}
ul li .icon {
  width: 30px;
  height: 30px;
  margin-right: 5px;
  border-radius: 100px;
  overflow: hidden;
}
ul li .icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
ul li .rank {
  position: absolute;
  display: inline-block;
  top: 17px;
  right: 0px;
  width: 15px;
  height: 17px;
}
ul li .rank img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
