<template>
  <v-app>
    <v-container>
      <h2 class="mb-10">よくあるご質問</h2>

      <v-row v-for="faq in faqs" :key="faq.question" class="mt-5">
        <v-col cols="12">
          <div class="my-3">
            <span class="fcolor" style="font-weight: bold">Q:</span> {{ faq.question }}
          </div>
          <div
            class="faq-answer"
            v-html="
              `<span class='label' style='color: blue; font-weight: bold'>A:</span> ${faq.answer}`
            "
          ></div>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script setup>
import { ref } from "vue";

const faqs = ref([
  {
    question: "いつくじの購入ができますか？",
    answer:
      "くじの購入は、各販売期間内のみ可能です。<br>配信のスケジュールなどのアップデート情報は公式X（旧twittter）でも確認ができます。",
  },
  {
    question: "配信時間外に購入はできますか？",
    answer:
      "くじの購入は、各販売期間内であれば配信時間外にも購入ができます。<br>各配信のアーカイブを視聴しながらくじの購入が可能です。",
  },
  {
    question: "くじの注文は完了していますか？",
    answer:
      "通信エラーなどで、購入時に注文は確定している（注文確定メールが来ている）にも関わらず、<br>くじが引けなかった場合、注文後5分以内に自動でくじが引かれます。<br>注文履歴よりご確認をお願いします。<br>もし5分以上経っても注文履歴に当該の注文が表示されない場合は、お問い合わせよりご連絡ください。",
  },
  {
    question: "スーパーコメントとはなんですか？",
    answer:
      "スーパーコメントとはチャット欄で自分のメッセージを目立たせる有料コメント機能です。<br>デジタルカードや限定ボイスなど（イベントによって異なります）がついております。<br>デジタルカードや限定ボイスなどは、イベント期間終了後（最終回配信の終了後）に配布されます。",
  },
  {
    question: "コメントを投稿したのですが、配信を閉じてしまいました。",
    answer:
      "コメント（スーパーコメントを含む）は、配信を開いた時点以降のコメントが表示されます。<br>つきまして配信を一度閉じてしまいますと以前のコメントは表示されません。配信者には問題ないく届いておりますのでご安心ください。",
  },
  {
    question: "推奨ブラウザーを教えてください。",
    answer:
      "＜Windows PCおよびMac推奨ブラウザー＞<br>Google Chrome 最新版<br><br>＜Androidスマートフォン推奨ブラウザー＞<br>Google Chrome 最新版<br><br>＜Phone推奨ブラウザー＞<br>Google Chrome 最新版<br>Safari 最新版<br><br>となります。<br>※フィーチャーフォンは対応しておりません。予めご了承ください。",
  },
  {
    question: "販売数制限はありますか？",
    answer: "販売数制限はございません。",
  },
  {
    question: "発送日や運送会社の指定はできますか？",
    answer: "申し訳ありませんが、配送日の指定および運送会社の指定は承っておりません。",
  },
  {
    question: "複数回配送費用を支払ったのに1個口で届きました。",
    answer:
      "配送費用につきましては配送にかかる費用の他に、人員のコストや梱包費用や梱包材やそれにかかるも含まれているため、<br>ご購入個数に応じて20個毎にいただいております。<br>つきましては実際のお届け箱数につきましては、同梱でお送りする場合がございます。",
  },
  {
    question: "ランキング賞とはなんですか？",
    answer:
      "ランキング賞は、各イベント毎にランキングが表示され結果に応じて特別なグッズをお届けします。<br>スーパーコメント購入数やくじの購入数など複数要因に応じてランキング順位が変動し表示されます。<br>ランキング賞は初回配信開始から加算され、最終回配信終了時点（イベント終了時点）でのランキング結果にてグッズをお届けします。<br>なお、ランキング順位の変動の要因等に関しての詳細については開示しておりません。予めご了承ください。",
  },
  {
    question: "機能追加要望やサービスについての要望があります。",
    answer:
      "ご要望についてはより良いサービスとするため随時募集しております。<a href='https://twitter.com/sukisukils'>公式X（旧twitter）</a>か<a href='https://sukisuki.live/inquiry'>お問い合わせフォーム</a>よりいただけますと幸いです。",
  },
  {
    question: "問い合わせ先は？",
    answer:
      "お問い合わせは<a href='https://sukisuki.live/inquiry'>こちらのお問い合わせフォーム</a>からお願いします。",
  },
]);
</script>

<style scoped>
.faq-answer {
  line-height: 1.6;
}
.fcolor {
  color: rgb(237, 121, 149);
}
</style>
