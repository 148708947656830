<script setup>
import { ref } from "vue";
import ArchiveViewOrganism from "/src/components/organisms/ArchiveViewOrganism.vue";
import { useRoute } from "vue-router";
import db from "../main.js"; //追加
import {
  doc,
  setDoc,
  onSnapshot,
  query,
  collection,
  getDocs,
  orderBy,
  limit,
  getDoc,
  where,
  Timestamp,
  // start_at,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

const props = defineProps({
  user: String,
});
// const router = useRouter();
const showmessage = ref([]);
const showranking = ref();

const route = useRoute();
const event_id = ref(route.query.event_id);
const live_id = ref(route.query.live_id);
const isLoadingforcredit = ref(true);
//メッセージを開いた時刻
// let siteOpenTime = new Date(); // JavaScriptのDateオブジェクト

// Timestampオブジェクトに変換
// let MessageTime = Timestamp.fromDate(livestartimejs.value);

// const q = query(
//   collection(db, "Goods_automessages", event_id.value, "automessages"),
//   orderBy("created_at", "asc"),
//   where("created_at", ">=", MessageTime) //where条件を追加
// );

// onSnapshot(q, (querySnapshot) => {
//   const nameshot = [];
//   querySnapshot.docChanges().forEach((change) => {
//     if (change.type === "added") {
//       const setmessage = change.doc.data();
//       showmessage.value.push(setmessage);
//     }
//     if (change.type === "modified") {
//       console.log("変更");
//     }
//     if (change.type === "removed") {
//       console.log("削除");
//     }
//   });
//   console.log(nameshot);
//   // showmessage.value = nameshot;
//   // console.log(showmessage.value);
// });

////videoのURL用とライブ開始時間を取得
const sendmessage = ref("testsendmessage");
const videourl = ref(null);
const videourlforondemand = ref(null);
const livestarttime = ref(null);
const livestartimejs = ref(null);
const MessageTime = ref(null);
const getvideurl = async () => {
  const docRef = doc(db, "Goods_liveshedule", live_id.value);
  const docSnap = await getDoc(docRef);
  videourl.value = docSnap.data().archive_videourl;
  videourlforondemand.value =
    process.env.VUE_APP_CLOUDFLARE_SUBDOMAIN +
    docSnap.data().videouid +
    "/manifest/video.m3u8?protocol=llhls";
  livestarttime.value = docSnap.data().livedate.start;
  livestartimejs.value = livestarttime.value.toDate();

  console.log("livestarttime: " + livestarttime.value);
  console.log("livestartimejs: " + livestartimejs.value);
  console.log("videourlforondemand: " + videourlforondemand.value);
  // Timestampオブジェクトに変換
  MessageTime.value = Timestamp.fromDate(livestartimejs.value);

  const q = query(
    collection(db, "Goods_automessages", event_id.value, "automessages"),
    orderBy("created_at", "asc"),
    where("created_at", ">=", MessageTime.value) //where条件を追加
  );

  // 一度だけクエリを実行
  getDocs(q)
    .then((querySnapshot) => {
      const nameshot = [];
      querySnapshot.forEach((doc) => {
        const setmessage = doc.data();
        showmessage.value.push(setmessage);
      });
      console.log(nameshot);
      console.log(showmessage.value);
    })
    .catch((error) => {
      console.error("Error getting documents: ", error);
    });
};
getvideurl();

//お茶代読み込み

const ochaPrice = ref(0);
const getochaprice = async () => {
  onSnapshot(doc(db, "Goods_products", event_id.value), (doc) => {
    ochaPrice.value = doc.data().ocha_price;
  });
};
getochaprice();

const addresses = ref([]);

async function getAddressData() {
  console.log("アドレス呼び出しー");
  addresses.value = [];
  const querySnapshot = await getDocs(collection(db, "users", props.user.uid, "addresses"));
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    addresses.value.push(data);
  });
}
getAddressData();
//登録済みクレジットカード呼び出し
const cardsInfo = ref(null);
const defaultCard = ref(null);
async function getCreditCard() {
  isLoadingforcredit.value = true;
  const functions = getFunctions();
  functions.region = "asia-northeast1";

  const myCallableFunction = httpsCallable(functions, "Common_cardslist");
  myCallableFunction({})
    .then((result) => {
      cardsInfo.value = result.data.cards.data;
      defaultCard.value = result.data.defaultCardId;

      isLoadingforcredit.value = false;
    })
    .catch((error) => {
      console.log(error.message);
    });
}
// getCreditCard();

const errorfortreca = ref(null); // エラー情報を保持するためのref
//トレカ購入（クレジットカード）
const supercommentDone = ref(false);
const comfirmBuy = (selectitem, choosebuycard, comments, cardId, trecaid) => {
  const functions = getFunctions();
  functions.region = "asia-northeast1";
  //refを消す
  const cardIdNo = cardId.value;
  const choosebuycardNo = choosebuycard.value;
  const commentsNo = comments.value;
  // const chooseaddressNo = chooseaddress.value;
  const event_idNo = event_id.value;
  const selectitemNo = selectitem.value;
  const trecaidNo = trecaid.value;
  const myCallableFunction = httpsCallable(functions, "Goods_TrecaCharge");
  myCallableFunction({
    card_id: cardIdNo,
    choosebuycard: choosebuycardNo,
    comments: commentsNo,
    event_id: event_idNo,
    orderItems: selectitemNo,
    trecaid: trecaidNo,
  })
    .then((result) => {
      console.log(result);
      supercommentDone.value = true;
    })
    .catch((err) => {
      console.log(err.message); // エラーメッセージをコンソールに出力
      errorfortreca.value = err; // エラー情報をrefにセット
    });
};

//トレカ購入（キャリア決裁）
const comfirmBuyforcarrierpayment = (
  selectitem,
  choosebuycard,
  comments,
  chooseaddress,
  cardId,
  trecaid
) => {
  const functions = getFunctions();
  functions.region = "asia-northeast1";
  //refを消す

  const choosebuycardNo = choosebuycard.value;
  const commentsNo = comments.value;
  const chooseaddressNo = chooseaddress.value;
  const event_idNo = event_id.value;
  const selectitemNo = selectitem.value;
  const trecaidNo = trecaid.value;
  const myCallableFunction = httpsCallable(functions, "Goods_TrecaChargeforcarrierpayment");
  myCallableFunction({
    choosebuycard: choosebuycardNo,
    comments: commentsNo,
    chooseaddress: chooseaddressNo,
    event_id: event_idNo,
    orderItems: selectitemNo,
    trecaid: trecaidNo,
  })
    .then((result) => {
      window.location.href = decodeURIComponent(result.data.redirectUrl);
    })
    .catch((error) => {
      console.log(error.message);
    });
};

const chooseGoods = async (selectnumber) => {
  if (props.user) {
    //カートへ書き込み
    await setDoc(
      doc(db, "users", props.user.uid, "Goods_productcartitems", "productcartitems"),
      {
        quantity: selectnumber.value,
        event_id: event_id.value,
        vendorname_ja: vendorname_ja.value,
      },
      {
        merge: false,
      }
    );
  }
};

const error = ref(null); // エラー情報を保持するためのref

//商品のグッズガチャ購入(クレジットカード)
const orderId = ref();
const comfirmGoods = (selectnumber, choosebuycard, chooseaddress, cardId) => {
  const functions = getFunctions();
  functions.region = "asia-northeast1";
  //refを消す
  const selectnumberNo = selectnumber.value;
  const choosebuycardNo = choosebuycard.value;
  const chooseaddressNo = chooseaddress.value;
  const event_idNo = event_id.value;
  const cardIdNo = cardId.value;
  //配信中の購入かどうか
  const buyfromlive = false;
  const myCallableFunction = httpsCallable(functions, "Goods_GoodsCharge");
  myCallableFunction({
    selectnumber: selectnumberNo,
    choosebuycard: choosebuycardNo,
    chooseaddress: chooseaddressNo,
    event_id: event_idNo,
    card_id: cardIdNo,
    shippingFee: shippingFee.value,
    buyfromlive: buyfromlive,
  })
    .then((result) => {
      orderId.value = result.data.stripeOrderId;
    })
    .catch((err) => {
      console.log(err.message); // エラーメッセージをコンソールに出力
      error.value = err; // エラー情報をrefにセット
    });
};

//paidyでの商品購入（キャプチャー）
const comfirmGoodsforpaidy = (selectnumber, chooseaddress, paidyPaymentId) => {
  console.log("親の親のpaidy");
  console.log(paidyPaymentId);
  const functions = getFunctions();
  functions.region = "asia-northeast1";

  // refを消す
  const selectnumberNo = selectnumber.value;
  const chooseaddressNo = chooseaddress.value;
  const event_idNo = event_id.value;

  const buyfromlive = true;

  console.log("paidyPaymentIdおや:", paidyPaymentId);
  console.log("selectnumber:", selectnumber);
  console.log("chooseaddress:", chooseaddress);
  console.log("event_id:", event_id);

  console.log("buyfromlive:", buyfromlive);

  // Paidy決済用のCallable Functionを呼び出し
  const myCallableFunction = httpsCallable(functions, "Goods_PaidyCharge");

  myCallableFunction({
    selectnumber: selectnumberNo,
    chooseaddress: chooseaddressNo,
    event_id: event_idNo,
    shippingFee: shippingFee.value,
    buyfromlive: buyfromlive,
    paidyPaymentId: paidyPaymentId, // すでに取得済みのPaidy Payment IDを渡す
  })
    .then((result) => {
      // 処理結果として、orderIdを取得
      orderId.value = paidyPaymentId;
      console.log("Order ID:", result);
    })
    .catch((err) => {
      console.log(err.message); // エラーメッセージをコンソールに出力
      error.value = err; // エラー情報をrefにセット
    });
};

//通常のコメントをデータベースへ書き込み
const commentinput = async (commenttext) => {
  const functions = getFunctions();
  functions.region = "asia-northeast1";
  //refを消す

  const event_idNo = event_id.value;
  const normalmessageNo = commenttext.value;
  const myCallableFunction = httpsCallable(functions, "Goods_putnormalcomment");
  myCallableFunction({
    normalmessage: normalmessageNo,
    event_id: event_idNo,
  })
    .then((result) => {
      console.log(result);
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error.message);
    });
};

//point呼び出し
const rankingarray = ref([]);
// const rankingno1 = ref(null);
// const rankingno2 = ref(null);
// const rankingno3 = ref(null);
const getpoint = async () => {
  const querypoint = query(
    collection(db, "Goods_ranking", event_id.value, "ranking"),
    limit(3),
    orderBy("points", "desc")
  );
  // , where("capital", "==", true));
  onSnapshot(querypoint, (querySnapshot) => {
    rankingarray.value = [];

    if (querySnapshot.empty) {
      const rankingarray = [];
      return rankingarray;
    }

    // const querySnapshot = await onSnapshot(querypoint);
    querySnapshot.forEach((doc) => {
      const setranking = doc.data();
      rankingarray.value.push(setranking);
    });
  });
};
getpoint();

//vtuberのグッズの商品データを取得
const goodsData = ref([]);
const getProductsData = async () => {
  const querySnapshot = await getDocs(collection(db, "Goods_products", event_id.value, "products"));
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    goodsData.value.push(data);
  });
};

getProductsData();

const sendaddress = async (postcode, address1, address2, name, phoneno) => {
  //refを消す
  const postcodeno = postcode.value;
  const address1no = address1.value;
  const address2no = address2.value;
  const nameno = name.value;
  const phonenono = phoneno.value;
  const functions = getFunctions();
  functions.region = "asia-northeast1"; // これを追加
  const addMessage = httpsCallable(functions, "Goods_Addaddress");
  await addMessage({
    postcode: postcodeno,
    address1: address1no,
    address2: address2no,
    name: nameno,
    phoneno: phonenono,
  }).then(async (res) => {
    console.log(res);
    await getAddressData();
    // dialog.value = false;
  });
};
//表示用の金額と送料
const shippingFee = ref();
const totallPrice = ref();
const quantity = ref();
const sumPrice = ref();

const shippingfeeandorderprice = async () => {
  const functions = getFunctions();
  functions.region = "asia-northeast1";
  const func = httpsCallable(functions, "Goods_calcOrderPrice_and_shippingFee");

  try {
    const res = await func({ name: "hoge" });
    shippingFee.value = res.data.price_fee.shippingFee;
    totallPrice.value = res.data.price_fee.productPrice + res.data.price_fee.shippingFee;

    const docRef = doc(db, "users", props.user.uid, "Goods_productcartitems", "productcartitems");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      quantity.value = data.quantity;
      sumPrice.value = data.quantity * unitPrice.value;
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    // console.log(error.message);
  }
};

//vendor情報取得及び販売期間中かどうかの判定
const vendorsData = ref();
const vendorname_ja = ref();
const unitPrice = ref();
const isSelling = ref(true);
let checkInterval = null;
const suppercommenttype = ref();
const vendorData = async () => {
  const docRef = doc(db, "Goods_products", event_id.value);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    vendorsData.value = docSnap.data();
    vendorname_ja.value = docSnap.data().vendor_ja;
    unitPrice.value = docSnap.data().price;
    //本来は子のコンポーネントでvendorsDataから取得すべき
    suppercommenttype.value = docSnap.data().supercommenttype;
    const endTimestamp = docSnap.data().eventdate?.end;
    if (endTimestamp) {
      const endDate = endTimestamp.toDate(); // FirebaseのタイムスタンプをJavaScriptのDateオブジェクトに変換
      const currentDate = new Date();

      if (currentDate > endDate) {
        console.log("販売終了");
        console.log(currentDate);
        console.log(endDate);
        isSelling.value = false;
        console.log(isSelling.value);
      } else {
        startCheckingEndDate(endDate);
      }
    }
  } else {
    // docSnap.data() will be undefined in this case
  }
};
vendorData();

const startCheckingEndDate = (endDate) => {
  // 1分ごとにチェック
  checkInterval = setInterval(() => {
    const currentDate = new Date();
    if (currentDate > endDate) {
      isSelling.value = false;
      clearInterval(checkInterval);
    }
  }, 60000); // 60000ミリ秒 = 1分
};

//デフォルトアドレスとデフォルトpaymentmethodの判定
const defaultAddress = ref(false);
const defaultpaymentmethod = ref(null);
const getDefaultAddress = () => {
  onSnapshot(doc(db, "users", props.user.uid, "sub_accounts", "sub_accounts"), (doc) => {
    defaultAddress.value = doc.data().default_address;
    defaultpaymentmethod.value = doc.data().default_paymentmethod;
  });
};
getDefaultAddress();

//商品のグッズガチャ購入(キャリア決裁)
const comfirmGoodsforcarrierpayment = (selectnumber, choosebuycard, chooseaddress) => {
  const functions = getFunctions();
  functions.region = "asia-northeast1";
  //refを消す
  const selectnumberNo = selectnumber.value;
  // const choosebuycardNo = choosebuycard.value;
  const chooseaddressNo = chooseaddress.value;
  const event_idNo = event_id.value;
  // const cardIdNo = cardId.value;
  const myCallableFunction = httpsCallable(functions, "Goods_requestCarrierPaymentforProducts");
  myCallableFunction({
    selectnumber: selectnumberNo,
    chooseaddress: chooseaddressNo,
    event_id: event_idNo,
    shippingFee: shippingFee.value,
  })
    .then((result) => {
      window.location.href = decodeURIComponent(result.data.redirectUrl);
      // console.log(result.data.stripeOrderId);
      // orderId.value = result.data.stripeOrderId;
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error.message);
    });
};
//キャリアペイメントのgoodsのダイアログを再び開いて後オーダーIDを追加
orderId.value = String(route.query.orderid);
const goods_dialogaftercarrier = ref(false);
const goods_dialogaftercarrierfortreca = ref(false);
if (orderId.value.startsWith("vl")) {
  goods_dialogaftercarrier.value = true;
} else if (orderId.value.startsWith("gt")) {
  goods_dialogaftercarrierfortreca.value = true;
} else {
  // goods_dialogaftercarrier.value = false;
}

//streaming中かどうかを判定する。streaming中でなければリダイレクト
// const streamingflag = ref(true);

// onSnapshot(doc(db, "Goods_products", event_id.value), (doc) => {
//   streamingflag.value = doc.data().nowstreaming;
//   if (streamingflag.value === false) {
//     router.push(`itemlist_v?event_id=${event_id.value}`);
//   } else {
//     console.log("streaming");
//   }
// });
const changecupercommentDone = () => {
  console.log("変更");
  supercommentDone.value = false;
};
</script>
<template>
  <ArchiveViewOrganism
    :showmessage="showmessage"
    :showranking="showranking"
    :flag="flag"
    :videourl="videourl"
    :videourlforondemand="videourlforondemand"
    :sendmessage="sendmessage"
    :addresses="addresses"
    :defaultAddress="defaultAddress"
    :defaultpaymentmethod="defaultpaymentmethod"
    :cardsInfo="cardsInfo"
    :rankingarray="rankingarray"
    :goodsData="goodsData"
    :orderId="orderId"
    :error="error"
    :errorfortreca="errorfortreca"
    :supercommentDone="supercommentDone"
    :defaultCard="defaultCard"
    :isLoadingforcredit="isLoadingforcredit"
    :shippingFee="shippingFee"
    :totallPrice="totallPrice"
    :sumPrice="sumPrice"
    :vendorsData="vendorsData"
    :goods_dialogaftercarrier="goods_dialogaftercarrier"
    :goods_dialogaftercarrierfortreca="goods_dialogaftercarrierfortreca"
    :isSelling="isSelling"
    :ochaPrice="ochaPrice"
    :suppercommenttype="suppercommenttype"
    @shippingfeeandorderprice="shippingfeeandorderprice"
    @comfirmBuy="comfirmBuy"
    @comfirmBuyforcarrierpayment="comfirmBuyforcarrierpayment"
    @comfirmGoodsforcarrierpayment="comfirmGoodsforcarrierpayment"
    @chooseGoods="chooseGoods"
    @comfirmGoods="comfirmGoods"
    @comfirmGoodsforpaidy="comfirmGoodsforpaidy"
    @sendaddress="sendaddress"
    :key="resetKey"
    ref="component"
    @commentinput="commentinput"
    @getAddressData="getAddressData"
    @getCreditCard="getCreditCard"
    @changecupercommentDone="changecupercommentDone"
    :MessageTime="MessageTime"
    :user="user"
    :vendorname_ja="vendorname_ja"
  />
</template>

<style scoped>
.reset {
  width: 500px;
  height: 3000px;
}
</style>

<!-- <template>
  <div>
    <video
      ref="videoPlayer"
      controls
      muted
      @canplaythrough="onCanPlayThrough"
      @error="onError"
    ></video>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";

const videoPlayer = ref(null);

const loadVideo = async () => {
  try {
    const url =
      "https://storage.googleapis.com/rf-dev-sukisuki-ls.appspot.com/2024-06-12%2003-35-27.mp4";
    videoPlayer.value.src = url;
    videoPlayer.value.load(); // 動画の読み込みを開始
  } catch (error) {
    console.error("Failed to load video URL:", error);
  }
};

const onCanPlayThrough = () => {
  console.log("Video can play through without interruption");
  videoPlayer.value.play();
};

const onError = (event) => {
  console.error("Failed to load video:", event);
};

onMounted(loadVideo);
</script>

<style scoped>
video {
  width: 100%;
  height: auto;
}
</style> -->
