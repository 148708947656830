<template>
  <div>
    <h2 class="mt-12 title">パスワード再設定</h2>
  </div>
  <form @submit.prevent="handleSubmit">
    <div class="mt-5 mailaddress">新しいパスワードを入力</div>

    <v-text-field
      class="ml-10 mr-10"
      v-model="password"
      :rules="passwordRules"
      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
      :type="show ? 'text' : 'password'"
      density="compact"
      required
      @click:append="() => (show = !show)"
    />
    <div>
      <v-btn
        class="mt-5"
        height="35"
        width="170"
        rounded="pill"
        @click="handleSubmit"
        color="rgb(237, 121, 149)"
      >
        <a class="login">パスワードをリセット</a>
      </v-btn>
    </div>
  </form>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { getAuth, verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
import { useRouter } from "vue-router";
const passwordRules = [(v) => !!v || "パスワードを入力してください"];
const auth = getAuth();
const actionCode = ref("");
const password = ref("");
const show = ref(false); // パスワードを表示するかどうかを制御する変数
const router = useRouter();
const toLink = async (path) => {
  try {
    await router.push(path);
    console.log("リダイレクト成功");
  } catch (error) {
    console.error("リダイレクト失敗:", error);
  }
};

onMounted(() => {
  const queryParams = new URLSearchParams(window.location.search);
  const oobCode = queryParams.get("oobCode") || "";
  actionCode.value = oobCode;
});

const handleSubmit = async () => {
  if (actionCode.value === "") return;

  try {
    await verifyPasswordResetCode(auth, actionCode.value);
    await confirmPasswordReset(auth, actionCode.value, password.value);
    console.log("success");
    // 成功した場合の処理（例：ログインページへのリダイレクト）をここに追加
    toLink("/changepassworddone");
  } catch (error) {
    console.error(error);
  }
};
</script>

<style scoped>
.mailaddress {
  font-size: 12px;
  text-align: left;
  margin-left: 40px;
}
.title {
  font-size: 14px;
}
</style>
