<script setup>
import { toRefs } from "vue";
const props = defineProps({
  vtuber_image: String,
});

const { vtuber_image } = toRefs(props);
</script>

<template>
  <div>
    <!-- <v-img :src="card.vtuber_image" contain /> -->
    <v-img max-height="300" :src="vtuber_image" contain />
  </div>
</template>
