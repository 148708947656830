<template>
  <v-app>
    <div class="maincontainer">
      <div>
        <h2 class="mt-12 title">パスワードの再設定完了</h2>
      </div>
      <div class="mt-12">
        <p>パスワードの再設定が完了しました</p>
        <p>新しいパスワードにてログインをお願いします</p>
      </div>
      <!--  <v-form ref="form" lazy-validation class="form">
          <div @click="deletemessage">
            <div class="mt-5 mailaddress">新しいパスワードを入力</div>
            <v-text-field
              class="ml-10 mr-10"
              v-model="newPassword"
              :rules="passwordRules"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              density="compact"
              required
              @click:append="() => (show = !show)"
            />
          </div>
          <div class="message">
            {{ text }}
          </div>
          <div>
            <v-btn
              class="mt-5"
              height="35"
              width="100"
              rounded="pill"
              @click="resetPassword"
              color="rgba(89, 117, 156)"
            >
              <a class="login">再設定</a>
            </v-btn>
          </div>
        </v-form>
      </div>
    </div> -->
    </div>
  </v-app>
</template>

<style scoped>
/* .form {
  width: 100%;
} */
.maincontainer {
  margin: 0 0 50px 0;
}
.title {
  font-size: 14px;
}
.login {
  color: rgb(255 255 255);
  font-size: 12px;
}
.resetpassword {
  font-size: 10px;
  text-decoration: underline;
}
.resetpasswordcontainer {
  margin: 8px 0 0 0;
}
.signup {
  color: rgb(255 255 255 100%);
  font-size: 12px;
}
.mailaddress {
  font-size: 12px;
  text-align: left;
  margin-left: 40px;
}
.message {
  color: red;
  font-size: 10px;
  margin: 10px 0 0 0;
}

/*スマートフォン-----------------------------------------------------------------------------------*/
/*@media only screen and (max-width:768px){*/
@media only screen and (max-width: 1000px) {
  .login {
    color: rgb(255 255 255);
    font-size: 12px;
  }
}
</style>
