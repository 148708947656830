<script setup>
import { ref, watch, onMounted, onUnmounted } from "vue";
import TheHeader from "/src/views/TheHeader.vue";
import TheFooter from "/src/views/TheFooter.vue";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useRoute } from "vue-router";
import { useMeta } from "./useMeta.js";

const user = ref(null);
const auth = getAuth();
const useruid = ref(null);
const userLoaded = ref(false);

onAuthStateChanged(auth, (userdata) => {
  if (userdata) {
    user.value = userdata;
    useruid.value = user.value.uid;
  } else {
    // console.log("logout");
  }
  userLoaded.value = true;
});

const defaultpage = ref(true);
const route = useRoute();
const windowWidth = ref(window.innerWidth);

// Windowのサイズを監視
const updateWidth = () => {
  windowWidth.value = window.innerWidth;
};
onMounted(() => {
  window.addEventListener("resize", updateWidth);
});
onUnmounted(() => {
  window.removeEventListener("resize", updateWidth);
});

// ルートとウィンドウの幅を考慮してdefaultpageを更新
watch([route, windowWidth], () => {
  if (route.path == "/main" && windowWidth.value < 960) {
    defaultpage.value = false;
  } else if (route.path == "/archive" && windowWidth.value < 960) {
    defaultpage.value = false;
  } else {
    defaultpage.value = true;
  }
});

useMeta();
</script>

<template>
  <template v-if="defaultpage">
    <v-app>
      <TheHeader />
      <v-main class="main">
        <router-view :user="user" :user-loaded="userLoaded" />
      </v-main>
      <TheFooter />
    </v-app>
  </template>
  <template v-else>
    <router-view :user="user" :user-loaded="userLoaded" />
  </template>
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap");

#app {
  font-family: "Noto Sans JP";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}
.v-application__wrap {
  min-height: 0 !important;
}
.main {
  min-height: 100%;
}

/* reset.css */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
a {
  background: none;
  text-decoration: none;
}
a:active,
a:hover {
  outline: 0;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
strong {
  font-weight: 700;
}
dfn {
  font-style: italic;
}
mark {
  background: #ff0;
  color: #000;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  border: 0;
  vertical-align: middle;
  -ms-interpolation-mode: bicubic;
}
svg:not(:root) {
  overflow: hidden;
}
hr {
  height: 0;
}
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: auto;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
}
button {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
button,
input {
  line-height: normal;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
legend {
  border: 0;
  white-space: normal;
}
textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}
optgroup {
  font-weight: 700;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
}
ul {
  list-style: none;
}
*,
:after,
:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
body,
html {
  width: 100%;
  height: 100%;
}
html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  line-height: 1em;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* reset.css END */

/* .container {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
  min-height: 100vh;
} */

a,
button {
  cursor: pointer;
}
/*以下vuetifyのレイアウトを無理やり上書き*/
.livecontainer {
  border: none !important;
  background-color: white !important;
}
</style>
