<script setup>
import { toRefs, computed } from "vue";
import { useRouter } from "vue-router";
import { formatDateJaFull } from "@/utils/date.js";

const props = defineProps({
  productsData: Array,
});

const { productsData } = toRefs(props);

const onAirProductsData = computed(() => {
  return productsData.value.filter((p) => {
    const now = Date.now();
    return p.eventdate.start.toDate() < now && now < p.eventdate.end.toDate();
  });
});

const endproductsData = computed(() => {
  return productsData.value.filter((p) => {
    return p.eventdate.end.toDate() < Date.now();
  });
});

const scheduledProductsData = computed(() => {
  return productsData.value.filter((p) => {
    return Date.now() < p.eventdate.start.toDate();
  });
});

const router = useRouter();

const toItemlist = (productsData) => {
  router.push(`itemlist_v?event_id=${productsData.event_id}`);
  // this.$router.push(`/itemlist?vtubername=${card.vtuber_en}`, () => {});
};
</script>

<template>
  <div>
    <div>
      <!-- 開催中 -->
      <v-row class="pc-ma-2 cancelrow" justify="center">
        <v-col
          v-for="(productsData, index) in onAirProductsData"
          :key="index"
          cols="12"
          sm="6"
          md="4"
          class="cancelpadding"
        >
          <!-- Image -->
          <v-img
            class="clickable-area"
            :src="productsData.vendor_image"
            @click="toItemlist(productsData)"
          />

          <!-- Card content -->
          <v-card class="custom-card">
            <div class="livecontainer pa-2 clickable-area" @click="toItemlist(productsData)">
              <div class="livestatus-onair mb-2">
                <p>開催中</p>
              </div>
              <h3 class="title mb-2">{{ productsData.eventtitle }}</h3>
              <p class="title mb-2">{{ productsData.livenumber }}</p>
              <p class="time mb-2">
                <v-icon size="20">mdi-timer</v-icon>
                {{
                  formatDateJaFull(productsData.eventdate.end.toDate()).replace(/:\d{2}$/, "")
                }}まで
              </p>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <!-- 開催予定 -->
      <v-row class="pc-ma-2 cancelrow" justify="center">
        <v-col
          v-for="(productsData, index) in scheduledProductsData"
          :key="index"
          cols="12"
          sm="6"
          md="4"
          class="cancelpadding"
        >
          <!-- Image -->
          <v-img
            class="clickable-area"
            :src="productsData.vendor_image"
            @click="toItemlist(productsData)"
          />

          <!-- Card content -->
          <v-card class="custom-card">
            <div class="livecontainer pa-2 clickable-area" @click="toItemlist(productsData)">
              <div class="livestatus-scheduled mb-2">
                <p>開催予定</p>
              </div>
              <h3 class="title mb-2">{{ productsData.eventtitle }}</h3>
              <p class="title mb-2">{{ productsData.livenumber }}</p>
              <p class="time mb-2">
                <v-icon size="20">mdi-timer</v-icon>
                {{
                  formatDateJaFull(productsData.eventdate.start.toDate()).replace(/:\d{2}$/, "")
                }}から
              </p>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <!-- 開催終了 -->
      <v-row class="pc-ma-2 cancelrow" justify="center">
        <v-col
          v-for="(productsData, index) in endproductsData"
          :key="index"
          cols="12"
          sm="6"
          md="4"
          class="cancelpadding"
        >
          <!-- Image -->
          <v-img
            class="clickable-area"
            :src="productsData.vendor_image"
            @click="toItemlist(productsData)"
          />

          <!-- Card content -->
          <v-card class="custom-card">
            <div class="livecontainer pa-2 clickable-area" @click="toItemlist(productsData)">
              <div class="livestatus-end mb-2">
                <p>開催終了</p>
              </div>
              <h3 class="title mb-2">{{ productsData.eventtitle }}</h3>
              <p class="title mb-2">{{ productsData.livenumber }}</p>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<style scoped>
.iconcontainer {
  margin: 30px 0 0 0;
  display: flex;
  /* align-items: center; */
  justify-content: center;
}
.icon {
  width: 50px;
  height: 50px;
  margin-right: 5px;
  border-radius: 100px;
  overflow: hidden;
}
.icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.livecontainer {
  border: solid 1px #454545;
}
.livestatus-onair {
  border: solid 1px rgb(178, 123, 211);
  display: inline-block;
  float: left;
  margin: 15px 0 5px 20px;
  background-color: rgb(178, 123, 211);
  color: white;
  padding: 5px 5px 5px 5px;
  font-size: 12px;
  /* text-align: left; */
}
.livestatus-scheduled {
  border: solid 1px #ffcceb;
  display: inline-block;
  float: left;
  margin: 15px 0 5px 20px;
  background-color: #ffcceb;
  color: white;
  padding: 5px 5px 5px 5px;
  font-size: 12px;
  /* text-align: left; */
}
.livestatus-end {
  border: solid 1px grey;
  display: inline-block;
  float: left;
  margin: 15px 0 5px 20px;
  background-color: grey;
  color: white;
  padding: 5px 5px 5px 5px;
  font-size: 12px;
  /* text-align: left; */
}
.title {
  clear: both;
  margin: 5px 0 5px 20px;
  text-align: left;
}
.time {
  margin: 5px 0 15px 20px;
  text-align: left;
}
.clickable-area {
  cursor: pointer;
}
.cancelrow {
  margin: 0px;
}
.cancelpadding {
  padding: 5px;
}

@media (min-width: 960px) {
  .pc-ma-2 {
    margin: 16px !important;
  }
}
</style>
