<script setup>
import { ref, computed } from "vue";

const emit = defineEmits(["commentinput"]);

const commenttext = ref("");
const maxLength = 200;

const commentLength = computed(() => {
  return Array.from(commenttext.value).reduce((sum, current) => {
    return sum + (current.charCodeAt(0) > 255 ? 2 : 1);
  }, 0);
});

const isOverMaxLength = computed(() => commentLength.value > maxLength);

const onKeypressEnter = async (event) => {
  if (isOverMaxLength.value) {
    event.preventDefault();
    return;
  } else if (commenttext.value == "") {
    return;
  }
  emit("commentinput", commenttext);
  deletecommentcontainer();
};

const deletecommentcontainer = () => {
  commenttext.value = "";
};

const onSubmit = async (event) => {
  if (isOverMaxLength.value) {
    event.preventDefault();
    return;
  } else if (commenttext.value == "") {
    return;
  }
  emit("commentinput", commenttext);
  deletecommentcontainer();
};
</script>
<template>
  <div class="input-container">
    <div class="input_comment">
      <input
        class="comment"
        :class="{ 'over-max-length': isOverMaxLength }"
        type="text"
        placeholder="コメントする"
        v-model="commenttext"
        @keypress.enter.prevent="onKeypressEnter"
      />
    </div>
    <div class="btn_message">
      <img class="submit-icon" src="img/sendmessage.png" @click="onSubmit" />
    </div>
  </div>
</template>

<style scoped>
.input-container {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  background-color: white;
  /* padding: 0px; */
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  /* margin-bottom: 10px;  */
  /* position: relative; */
}
.input_comment {
  width: calc(100% - 45px);
  text-align: left;
}
.comment {
  color: #000; /* normal text color */
  /* flex-grow: 1; */
  border: none;
  outline: none;
  /* width: 100%; */
  padding: 8px 8px 8px 8px;
  width: 100%;
  /* height: 39px; */
  /* margin-left: -20px; */
}

.btn_message {
  width: 45px;
  text-align: center;
}

.comment.over-max-length {
  color: #f00; /* text color when over max length */
}

.submit-icon {
  cursor: pointer;
  transform: scale(0.5); /* scale the icon to 80% of its original size */
}
</style>
