<script setup>
import { ref, watch, onMounted } from "vue";
import Hls from "hls.js";

const props = defineProps({
  videourl: String,
  isMuted: {
    type: Boolean,
    default: true,
  },
});

const videoElement = ref(null);
let hls = null;
let triedWithoutProtocol = false;

const loadVideo = (url) => {
  console.log(`Attempting to load video from URL: ${url}`);
  if (hls) {
    hls.destroy();
  }
  if (Hls.isSupported()) {
    hls = new Hls();
    hls.attachMedia(videoElement.value);
    hls.on(Hls.Events.MEDIA_ATTACHED, () => {
      hls.loadSource(url);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        videoElement.value.play().catch((error) => {
          console.log(`Failed to play video: ${error}`);
        });
      });
    });
    hls.on(Hls.Events.ERROR, (event, data) => {
      if (data.fatal || (data.response && data.response.code === 500)) {
        if (!triedWithoutProtocol && url.includes("?protocol=llhls")) {
          triedWithoutProtocol = true;
          const newUrl = url.replace("?protocol=llhls", "");
          console.log(`Retrying with URL: ${newUrl}`);
          loadVideo(newUrl);
        }
      }
    });
  } else if (videoElement.value.canPlayType("application/vnd.apple.mpegurl")) {
    videoElement.value.src = url;
    videoElement.value.addEventListener("error", () => {
      if (!triedWithoutProtocol && url.includes("?protocol=llhls")) {
        triedWithoutProtocol = true;
        const newUrl = url.replace("?protocol=llhls", "");
        console.log(`Retrying with URL: ${newUrl}`);
        videoElement.value.src = newUrl;
      }
    });
    videoElement.value.addEventListener("loadedmetadata", () => {
      videoElement.value.play().catch((error) => {
        console.log(`Failed to play video: ${error}`);
      });
    });
  }
};

watch(
  () => props.videourl,
  (newUrl) => {
    if (newUrl) {
      loadVideo(newUrl);
    }
  }
);

onMounted(() => {
  loadVideo(props.videourl);
});
</script>

<template>
  <div class="movie">
    <div class="movie_inner">
      <video
        ref="videoElement"
        :autoplay="true"
        :muted="isMuted"
        :loop="true"
        playsinline
        height="100%"
      ></video>
    </div>
  </div>
</template>

<style scoped>
.movie {
  width: 100%;
  height: 100%;
}
.movie .movie_inner {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.vjs_video_3-dimensions {
  width: 100%;
  height: 100%;
}
.movie .movie_inner video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (min-width: 960px) {
  .movie {
    width: auto;
    height: 80vh;
  }
  .movie .movie_inner {
    width: auto;
    height: 80vh;
    overflow: hidden;
  }
  .vjs_video_3-dimensions {
    width: auto;
    height: 80vh;
  }
  .movie .movie_inner video {
    position: initial;
    width: auto;
    height: 80vh;
    object-fit: cover;
  }
}
</style>
