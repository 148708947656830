<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { getFunctions, httpsCallable } from "firebase/functions";

const router = useRouter();
const orderNumber = ref(null);
const state = ref(null);
const type = ref(null);
const errorMessage = ref("");
const isLoading = ref(true);
orderNumber.value = router.currentRoute.value.query.order_number;
state.value = router.currentRoute.value.query.state;
type.value = router.currentRoute.value.query.type;
const toHome = () => {
  router.push("/");
};

const confirmPayment = () => {
  const functions = getFunctions();
  functions.region = "asia-northeast1";

  const confirmCarrierPayment = httpsCallable(functions, "Goods_confirmCarrierPayment");
  confirmCarrierPayment({
    orderNumber: orderNumber.value,
  })
    .then((res) => {
      if (res.data.result === "success") {
        router.push(`/main?vendorname=${res.data.vtubername}&orderid=${orderNumber.value}`);
      } else {
        isLoading.value = false;
        errorMessage.value = res.data.message;
      }
    })
    .catch((error) => {
      isLoading.value = false;
      errorMessage.value = error.message;
    });
};

const confirmPaymentforTreca = () => {
  const functions = getFunctions();
  functions.region = "asia-northeast1";

  const confirmCarrierPayment = httpsCallable(functions, "Goods_confirmCarrierPaymentforTreca");
  confirmCarrierPayment({
    orderNumber: orderNumber.value,
  })
    .then((res) => {
      if (res.data.result === "success") {
        router.push(`/main?vendorname=${res.data.vtubername}&orderid=${orderNumber.value}`);
      } else {
        isLoading.value = false;
        errorMessage.value = res.data.message;
      }
    })
    .catch((error) => {
      isLoading.value = false;
      errorMessage.value = error.message;
    });
};

if (!orderNumber.value) {
  toHome();
} else if (!state.value) {
  router.push("/cart");
} else if (type.value === "goods") {
  confirmPayment();
} else if (type.value === "treca") {
  confirmPaymentforTreca();
}
</script>

<template>
  <v-container>
    <h1 v-if="!errorMessage">決済を行っています。しばらくこのままお待ちください。</h1>
    <h1 v-else>
      {{ errorMessage }}
    </h1>

    <div v-show="isLoading" class="text-xs-center">
      <v-progress-circular :size="50" color="primary" indeterminate />
    </div>

    <v-btn v-if="errorMessage" @click="toHome"> ホームへ戻る </v-btn>
  </v-container>
</template>
