<script setup>
import { getAuth, signOut } from "firebase/auth";
import { useRouter } from "vue-router";

const router = useRouter();
const toLink = (url) => {
  router.push(url);
};
const signout = () => {
  const auth = getAuth();
  signOut(auth)
    .then(() => {
      // Sign-out successful.
      router.push("/login");
    })
    .catch((error) => {
      console.log(error);
      // An error happened.
    });
};
const items = [
  {
    link: "editprofile",
    image: "img/myaccount_profile.png",
    text: "▶︎プロフィール情報の編集",
    class: "info",
  },
  {
    link: "editpaymentinfo",
    image: "img/myaccount_payment.png",
    text: "▶︎お支払い方法の変更",
    class: "payment",
  },
  {
    link: "myaddress",
    image: "img/myaccount_address.png",
    text: "▶︎お届け先情報の変更",
    class: "address",
  },
  {
    link: "orderhistory",
    image: "img/myaccount_order.png",
    text: "▶︎注文履歴",
    class: "order",
  },
];
</script>
<template>
  <v-app>
    <v-container class="maincontainer" fluid>
      <v-row class="text-center mt-5">
        <v-col cols="12">
          <h2 class="title">マイアカウント</h2>
        </v-col>
      </v-row>

      <v-row class="text-center ml-15 mr-15 mt-5">
        <v-col cols="12" md="6" lg="3" v-for="item in items" :key="item.text">
          <div class="box clickable-area" @click="toLink(item.link)">
            <img :src="item.image" alt="edit" class="edit" />
            <div :class="item.class">{{ item.text }}</div>
          </div>
        </v-col>
      </v-row>

      <v-row class="justify-center">
        <v-col cols="12" class="text-right mr-10">
          <a class="logout" @click="signout()"> ▶︎ログアウト </a>
        </v-col>
      </v-row>

      <v-row class="justify-center mt-5">
        <v-col cols="12" class="text-center">
          <v-btn
            variant="outlined"
            height="30"
            width="120"
            rounded="pill"
            color="black"
            class="btn"
            @click="toLink('/')"
          >
            トップページへ
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<style scoped>
/* .form {
  width: 100%;
} */
.maincontainer {
  margin: 0 0 50px 0;
}
.title {
  font-size: 14px;
}
.box {
  border-radius: 20px;
  border: 1px solid #000;
  background-color: white;
  width: 200px;
  height: 75px;
  padding: 0.5em 1em;
  /* margin: 2em 0; */
  margin-left: auto;
  margin-right: auto;
  box-shadow: rgba(233, 233, 233, 0.9) 5px 5px 0px 0px;
}

.edit {
  height: 45px;
  margin: 10px 130px 0px 0;
  /* position: absolute;
  top: 0;
  left: 0.001; */
}
.info {
  font-size: 10px;
  margin: -33px -47px 0px 0;
}
.payment {
  font-size: 10px;
  margin: -33px -30px 0px 0;
}
.address {
  font-size: 10px;
  margin: -33px -30px 0px 0;
}
.order {
  font-size: 10px;
  margin: -33px 20px 0 0;
}
.logout {
  font-size: 10px;
  text-decoration: underline;
  color: rgb(165 71 71);
}
.logoutcontainer {
  margin: 8px 0 0 300px;
}

.btn {
  background-color: rgb(226, 226, 226);
  box-shadow: rgba(233, 233, 233, 0.9) 3px 3px 0px 0px;
}
.returncontainer {
  margin: 100px 0 0 0;
}
.return {
  color: rgb(142 144 147);
  font-size: 12px;
}

.clickable-area {
  cursor: pointer;
}
/*スマートフォン-----------------------------------------------------------------------------------*/
/*@media only screen and (max-width:768px){*/
/* @media only screen and (max-width: 1000px) {
  .login {
    color: rgb(255 255 255);
    font-size: 12px;
  }
} */
/* 追加のレスポンシブ対応スタイル */
/* @media only screen and (min-width: 769px) {
  .box {
    max-width: 240px;
    margin: 0 auto;
  }
} */
</style>
