<template>
  <div>
    <object :data="pdfUrl" type="application/pdf" width="100%" height="900px" style="border: none">
      <!-- オブジェクトタグ内にフォールバックコンテンツを提供 -->
      <p style="margin-top: 30px">
        SukiSukiらいぶすてーしょんの資料は
        <a :href="pdfUrl">こちら</a>からダウンロードしてください。
      </p>
    </object>
  </div>
</template>

<script setup>
import { ref } from "vue";

const pdfUrl = ref(
  "https://firebasestorage.googleapis.com/v0/b/rf-prd-sukisuki-ls.appspot.com/o/live_station_images_common%2FSUKISUKILS%E3%81%93%E3%82%99%E7%B4%B9%E4%BB%8B%E8%B3%87%E6%96%99.pdf?alt=media&token=1b8483d5-56b9-409f-9165-4e432d3e5226"
);
</script>
