import { watchEffect } from "vue";
import { useRoute } from "vue-router";

export function useMeta() {
  const route = useRoute();

  watchEffect(() => {
    if (route.meta.title) {
      document.title = route.meta.title;
    }
    if (route.meta.description) {
      let desc = document.querySelector('meta[name="description"]');
      if (!desc) {
        desc = document.createElement("meta");
        desc.name = "description";
        document.head.appendChild(desc);
      }
      desc.content = route.meta.description;
    }
  });
}
