<script setup>
// eslint-disable-next-line no-undef
import { ref, onMounted } from "vue";
import { stripe } from "/src/stripe.js";
import { getFunctions, httpsCallable } from "firebase/functions";

const emit = defineEmits(["addcardclose"]);
const errortext = ref(null);
const error = ref(false);

const closeerrormessage = () => {
  error.value = false;
};
const elements = stripe.elements();

const elementStyles = {
  base: {
    color: "#32325D",
    fontWeight: 500,
    // fontFamily: "Source Code Pro, Consolas, Menlo, monospace",
    fontSize: "14px",
    fontSmoothing: "antialiased",
    "::placeholder": {
      color: "#CFD7DF",
    },
    ":-webkit-autofill": {
      color: "#e39f48",
    },
  },
  invalid: {
    color: "#E25950",
    "::placeholder": {
      color: "#FFCCA5",
    },
  },
};
const elementClasses = {
  focus: "focused",
  empty: "empty",
  invalid: "invalid",
};
const cardNumber = ref(null);
onMounted(() => {
  cardNumber.value = elements.create("cardNumber", {
    style: elementStyles,
    classes: elementClasses,
  });
  cardNumber.value.mount("#card-number");

  // // カードの有効期限
  const cardExpiry = elements.create("cardExpiry", {
    style: elementStyles,
    classes: elementClasses,
  });
  cardExpiry.mount("#card-expiry");

  // // カードのCVC入力
  const cardCvc = elements.create("cardCvc", {
    style: elementStyles,
    classes: elementClasses,
  });
  cardCvc.mount("#card-cvc");
});

// トークンを作成
// cardNumberのみを指定する

const addcardfun = () => {
  // emit("addcardload");
  stripe.createToken(cardNumber.value, {}).then(async function (result) {
    if (result.error) {
      // eslint-disable-next-line no-console
      this.text = result.error.message;
      this.snackbar = true;
      this.isLoading = false;
      emit("addcardloadoff");
    } else {
      emit("addcardload");
      // await setStripeCardInfo(result.token, this.prevRoute.name);
      // eslint-disable-next-line no-console
      const functions = getFunctions();
      functions.region = "asia-northeast1"; // これを追加
      const addMessage = httpsCallable(functions, "Common_addcards");
      await addMessage(result.token)
        .then((res) => {
          // eslint-disable-next-line no-console
          // エラーが起きた
          if (res.data.error) {
            emit("addcardloadoff");
            // eslint-disable-next-line no-console

            if (res.data.error.code === "card_declined") {
              emit("addcardloadoff");
              errortext.value = "カード登録に失敗しました";
              error.value = true;
            } else if (res.data.error.code === "incorrect_cvc") {
              emit("addcardloadoff");
              errortext.value = "セキュリティーコードが間違っています";
              error.value = true;
            } else if (res.data.error.code === "incorrect_expiry") {
              emit("addcardloadoff");
              errortext.value = "有効期限が間違っています";
              error.value = true;
            } else if (res.data.error.code === "processing_error") {
              emit("addcardloadoff");
              errortext.value = "処理中にエラーが発生しました";
              error.value = true;
            }
          } else {
            // eslint-disable-next-line no-console
            addcardclose();
            // window.location.href = '/myaccount/wallet';
            // this.$router.push("/wallet");
          }
        })
        .catch((error) => {
          emit("addcardloadoff");
          // eslint-disable-next-line no-console
          this.text = error.message;
        });
    }
  });
};

const addcardclose = () => {
  emit("addcardclose");
};

// const afteraddcardclose = () => {
//   emit("afteraddcardclose");
// };
</script>

<template>
  <div class="body">
    <div>クレジットカード情報の追加</div>

    <!-- <div class="cardtitle">カード番号</div> -->
    <div class="field">
      <p class="title">カード番号</p>
      <div id="card-number" class="inputcard" @click="closeerrormessage()"></div>
    </div>
    <!-- <input class="cardinput" type="number" v-model="cardno" /> -->

    <!-- <div class="cardtitle">有効期限</div> -->
    <div class="field">
      <p class="title">有効期限</p>
      <div id="card-expiry" class="inputexpiry"></div>
    </div>

    <div class="field">
      <p class="title">セキュリティー<br />コード</p>
      <div id="card-cvc" class="inputcvc"></div>
    </div>
    <!-- <input class="cvccode" type="text" placeholder="" v-model="cvc" /> -->
    <div class="unit">
      <div class="credittypetitle">以下のクレジットカードをご利用いただけます。</div>
      <img src="img/visa.png" class="logopic" />
      <img src="img/mastercard.png" class="logopic" />
      <img src="img/AXP.jpg" class="logopic" />
      <img src="img/jcb.png" class="logopic" />
    </div>
    <div class="errormessage" v-show="error">{{ errortext }}</div>
    <div class="btn_conteiner">
      <button class="return" @click="toLink('myaccount')">
        <a @click="addcardclose()">キャンセル</a>
      </button>
      <button class="normal" @click="addcardfun">カードを追加</button>
    </div>
  </div>
</template>

<style scoped>
.body {
  padding: 0px 15px;
  font-size: 0.9rem;
  line-height: 1.5em;
}
.cardno {
  font-size: 10px;
  margin: 0 0px 0 0;
  text-align: left;
  margin: 0 0 0 20px;
}
.field {
  margin-bottom: 10px;
  vertical-align: middle;
}
.field .title {
  font-size: 0.8rem;
  display: inline-block;
  width: 100px;
  padding-bottom: 0;
  vertical-align: middle;
}
.inputcard,
.inputexpiry,
.inputcvc {
  font-size: 1rem;
  margin: 0 0 0 0px;
  outline: 1px solid;
  padding: 8px;
  border: none;
  border-bottom: #666 solid 1px;
  background-color: #eeeeee;
  outline: none;
  display: inline-block;
  vertical-align: middle;
}
.inputcard {
  width: 200px;
}
.inputexpiry {
  width: 100px;
}
.inputcvc {
  width: 100px;
}

.cardno {
  font-size: 12px;
  margin: -14px 100px 0px 0;
}

.addpayment {
  color: rgb(255 255 255);
  font-size: 10px;
}

.return {
  color: rgb(142 144 147);
  font-size: 12px;
}
.dialog {
  background-color: white;
  padding: 0.5em 1em;
  margin: 0 0;
}

.bottonconteiner {
  margin: 50px 0 20px 0;
  display: flex;
}

.credittypetitle {
  font-size: 10px;
  margin: 10px 0 0 0;
}

.logopic {
  max-height: 20px;
  margin: 5px 5px 0 0;
}
.errormessage {
  margin: 10px 0 0 0;
  font-size: 12px;
  color: red;
}
.modal .btn_conteiner {
  text-align: center;
}
.modal button.normal,
.modal button.return {
  padding: 10px 15px;
  font-size: 0.9rem;
  border-radius: 100px;
  border: none;
  margin: 0 5px;
  background: rgb(237, 121, 149);
  color: #ffffff;
}
.modal button.normal:active {
  background: rgb(237, 121, 149);
}
.modal button.return {
  background: rgb(226, 226, 226);
  color: rgb(142 144 147);
}
.modal button.return:active {
  background: rgb(190, 190, 190);
  color: rgb(255 255 255);
}
.modal .body .unit {
  margin-bottom: 15px;
}
@keyframes ellipsis {
  0% {
    content: " .  ";
  }
  33% {
    content: " .. ";
  }
  67% {
    content: " ...";
  }
  100% {
    content: "    ";
  }
}

.loading-message {
  color: white; /* 白色の文字色 */
  font-size: 24px;
  font-weight: bold;
}

.loading-dots::after {
  content: "";
  display: inline-block;
  width: 1em;
  animation: ellipsis 2s infinite;
  color: white; /* 白色の文字色 */
}
</style>
