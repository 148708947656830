<template>
  <li class="btnhart"><img @click="sendHeart" src="img/btn_hart.png" /></li>
  <div class="live-stream">
    <div class="hearts-container">
      <div
        v-for="(heart, index) in hearts"
        :key="index"
        class="heart"
        :style="{ left: heart.left + 'px', animationDuration: heart.duration + 's' }"
      >
        &#10084;
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive } from "vue";

const hearts = ref([]);

const heartSize = 60; // adjust as needed

function sendHeart() {
  const heart = reactive({
    left: Math.random() * (window.innerWidth - heartSize),
    duration: Math.random() * 5 + 1, // random duration between 1 and 6 seconds
  });

  hearts.value.push(heart);

  // remove heart after animation is done
  setTimeout(() => {
    const index = hearts.value.indexOf(heart);
    hearts.value.splice(index, 1);
  }, heart.duration * 1000);
}
</script>

<style scoped>
.live-stream {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 80%;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: -1;
}

.hearts-container {
  width: 100%;
  height: 100%;
}

.heart {
  position: absolute;
  bottom: 0;
  font-size: 60px;
  color: #ff1493; /* ディープピンク色 */
  animation: float 1s linear infinite;
}

@keyframes float {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100vh);
    opacity: 0;
  }
}
.item_list ul {
  display: flex;
  flex-wrap: no-wrap;
}
.item_list ul li {
  width: 40px;
  margin-left: 10px;
}
.item_list ul li img {
  width: 100%;
}
.btnhart {
  cursor: pointer;
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.5));
}
@media (min-width: 960px) {
  .live-stream {
    width: 50%;
    top: 80px;
    left: 0;
  }
}
</style>
