<script setup>
import { collection, getDocs, getDoc } from "firebase/firestore";
import db from "../main.js";
import { ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter(); // 追加: 現在のrouterインスタンスを取得

const props = defineProps({
  user: String,
});

const orders = ref([]);

const getOrdernumber = async () => {
  const querySnapshot = await getDocs(
    collection(db, "users", props.user.uid, "Goods_productorders")
  );
  querySnapshot.forEach(async (doc) => {
    const data = doc.data();
    const historyref = data.orders;

    const docSnap = await getDoc(historyref);

    if (docSnap.exists()) {
      const totalPrice = docSnap.data().total_price;
      const orderNumber = docSnap.id;
      const timestamp = docSnap.data().created_at; // FirestoreのTimestamp
      const vendorname_ja = docSnap.data().vendorname_ja;

      // FirestoreのTimestampからDateオブジェクトを作成
      const date = new Date(timestamp.seconds * 1000);
      // Dateオブジェクトから人間が読める形式の日付を生成
      const created_at = date.toLocaleDateString();

      orders.value.push({
        totalPrice,
        orderNumber,
        created_at,
        vendorname_ja,
      });
    }
  });
};

getOrdernumber();

const toLink = (orderNumber) => {
  // 追加: クリックイベントハンドラ
  router.push({
    name: "orderhistorygoodsdetail",
    query: { orderNumber },
  });
};

const toLinkto = (url) => {
  router.push(url);
};
</script>

<template>
  <v-app>
    <div class="maincontainer">
      <div class="title-container">
        <h2 class="title">限定グッズ注文履歴</h2>
      </div>
    </div>
    <!-- 追加: v-forディレクティブを使用して、各注文情報を表示 -->
    <div v-for="(order, index) in orders" :key="index">
      <div class="historycontainer clickable-area" @click="toLink(order.orderNumber)">
        <div class="historyitem">
          <span class="historylabel">注文番号</span>
          <span class="ordernumbertext">{{ order.orderNumber }}</span>
        </div>
        <div class="historyitem">
          <span class="historylabel">注文日</span> {{ order.created_at }}
        </div>
        <div class="historyitem">
          <span class="historylabel">ご請求額</span> {{ order.totalPrice }}円
        </div>
        <div class="historyitem">
          <span class="historylabel">配信者名</span> {{ order.vendorname_ja }}
        </div>
      </div>
    </div>
    <div class="returncontainer" @click="toLinkto('orderhistory')">
      <v-btn variant="outlined" height="30" width="65" rounded="pill" color="black" class="btn">
        <a class="return">戻る</a>
      </v-btn>
    </div>
  </v-app>
</template>

<style scoped>
.title-container {
  margin: 50px 50px;
}

.historycontainer {
  border: 1px solid black;
  border-radius: 25px;
  padding: 20px;
  margin: 10px 30px;
  box-shadow: rgba(233, 233, 233, 0.9) 5px 5px 0px 0px;
}

.historyitem {
  padding: 10px;
  margin-bottom: 1px; /* 各項目の間にマージンを設定 */
  display: flex; /* フレックスコンテナにする */
  align-items: center; /* フレックスアイテム（ラベルとデータ）を垂直方向中央に揃える */
  font-size: 13px;
}

.historylabel {
  background-color: lightgray;
  padding: 5px 15px;
  border: 1px solid black;
  border-radius: 15px;
  margin-right: 10px;
  text-align: center; /* ラベルのテキストを中央寄せにする */
  flex-shrink: 0;
  min-width: 100px;
}

.returncontainer {
  margin: 30px 0 0 0;
}
.return {
  color: rgb(142 144 147);
  font-size: 12px;
}

.ordernumbertext {
  font-size: 10px;
}

.clickable-area {
  cursor: pointer;
}
</style>
