<script setup>
import { ref, toRefs, watchEffect } from "vue";

const props = defineProps({
  vendorsData: Array,
});

const { vendorsData } = toRefs(props);
const vendorname = ref();
const eventtitle = ref();
const iconURL = ref();

// vendorsDataが変更されるたびに実行される
watchEffect(() => {
  if (vendorsData.value) {
    vendorname.value = vendorsData.value.vendor_ja;
    eventtitle.value = vendorsData.value.eventtitle;
    iconURL.value = vendorsData.value.iconURL;
  }
});
</script>

<template>
  <div class="distributor">
    <div class="distributor_icon">
      <!-- 配信者のアイコンを表示させる 未実装 -->
      <img :src="iconURL" />
    </div>
    <div class="distributor_info">
      <!-- 配信者の情報を表示させる 未実装 -->
      <div class="distributor_name">{{ vendorname }}</div>
      <div class="distributor_eventname">
        <!-- イベントの情報を表示させる 未実装 -->
        {{ eventtitle }}
      </div>
    </div>
  </div>
</template>

<style scoped>
.distributor {
  display: flex;
  margin-bottom: 10px;
}
.distributor_icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 100px;
  overflow: hidden;
}
.distributor_icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.distributor_info {
  color: #fff;
  width: calc(100% - 50px);
}
.distributor_name {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
  margin-bottom: 5px;
  text-align: left;
}
.distributor_eventname {
  width: 100%;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
}
</style>
