<script setup>
import { ref } from "vue";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useRouter, useRoute } from "vue-router";

const email = ref(null);
const emailRules = [
  (v) => !!v || "メールアドレスを入力してください",
  (v) => /.+@.+\..+/.test(v) || "メールアドレスを入力してください",
];
const password = ref(null);
const show = ref(false);
const passwordRules = [(v) => !!v || "パスワードを入力してください"];
const text = ref(null);
const loadingforlogin = ref(false);
const router = useRouter();
const toLink = (url) => {
  router.push(url);
};

const route = useRoute();
const redirectURL = ref(null);
redirectURL.value = ref(route.query.redirect);
const signIn = () => {
  loadingforlogin.value = true;

  const auth = getAuth();
  signInWithEmailAndPassword(auth, email.value, password.value)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      console.log(user);

      if (redirectURL.value.value == null) {
        loadingforlogin.value = false;
        toLink("/");
      } else {
        loadingforlogin.value = false;

        toLink(redirectURL.value.value);
      }
    })
    .catch((error) => {
      loadingforlogin.value = false;

      if (error.code === "auth/user-not-found") {
        text.value = "メールアドレスかパスワードに誤りがあります";
      } else if (error.code === "auth/invalid-email") {
        text.value = "メールアドレスの形式をご確認ください";
      } else {
        text.value = "メールアドレスかパスワードに誤りがあります";
      }
    });
};
const deletemessage = () => {
  text.value = null;
};
</script>
<template>
  <v-app>
    <div v-if="loadingforlogin" class="loading-overlay">
      <div class="loading-message">ログイン中<span class="loading-dots"></span></div>
    </div>
    <div class="maincontainer">
      <div>
        <h2 class="mt-12 title">SukiSukiアカウントにログイン</h2>
      </div>
      <div class="mt-12">
        <v-form ref="form" lazy-validation class="form">
          <div @click="deletemessage">
            <div class="mailaddress">メールアドレス</div>
            <v-text-field
              class="ml-10 mr-10"
              v-model="email"
              :rules="emailRules"
              density="compact"
              required
            />
          </div>
          <div @click="deletemessage">
            <div class="mt-5 mailaddress">パスワードを入力</div>

            <v-text-field
              class="ml-10 mr-10"
              v-model="password"
              :rules="passwordRules"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              density="compact"
              required
              @click:append="() => (show = !show)"
            />
          </div>
          <div class="message">
            {{ text }}
          </div>
          <div>
            <v-btn
              class="mt-5"
              height="35"
              width="100"
              rounded="pill"
              @click="signIn"
              color="rgb(237, 121, 149)"
            >
              <a class="login">ログイン</a>
            </v-btn>
          </div>
        </v-form>
      </div>
      <div class="resetpasswordcontainer">
        <a class="resetpassword" @click="toLink('/resetpassword')">
          ▶︎パスワードをお忘れの方はこちら
        </a>
      </div>
      <div>
        <v-btn
          class="mt-16 mb-16"
          height="35"
          width="100"
          rounded="pill"
          @click="toLink('/signup')"
          color="rgb(60, 39, 35,41%)"
        >
          <a class="signup">新規登録</a>
        </v-btn>
      </div>
    </div>
  </v-app>
</template>

<style scoped>
/* .form {
  width: 100%;
} */
.maincontainer {
  margin: 0 0 50px 0;
}
.title {
  font-size: 14px;
}
.login {
  color: rgb(255 255 255);
  font-size: 12px;
}
.resetpassword {
  font-size: 10px;
  text-decoration: underline;
}
.resetpasswordcontainer {
  margin: 8px 0 0 0;
}
.signup {
  color: rgb(255 255 255 100%);
  font-size: 12px;
}
.mailaddress {
  font-size: 12px;
  text-align: left;
  margin-left: 40px;
}
.message {
  color: red;
  font-size: 10px;
  margin: 10px 0 0 0;
}

/*スマートフォン-----------------------------------------------------------------------------------*/
/*@media only screen and (max-width:768px){*/
@media only screen and (max-width: 1000px) {
  .login {
    color: rgb(255 255 255);
    font-size: 12px;
  }
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.5); /* 半透明の灰色背景色 */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* 追加：z-indexプロパティ */
}
@keyframes ellipsis {
  0% {
    content: " .  ";
  }
  33% {
    content: " .. ";
  }
  67% {
    content: " ...";
  }
  100% {
    content: "    ";
  }
}

.loading-message {
  color: white; /* 白色の文字色 */
  font-size: 24px;
  font-weight: bold;
}

.loading-dots::after {
  content: "";
  display: inline-block;
  width: 1em;
  animation: ellipsis 2s infinite;
  color: white; /* 白色の文字色 */
}
</style>
