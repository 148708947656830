<script setup>
import { getDoc, doc, collection, getDocs } from "firebase/firestore";
import db from "../main.js";
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router"; // 追加: useRouteのインポート

const orderDetails = ref({});

const route = useRoute(); // 追加: 現在のルートオブジェクトを取得

const orderNumber = ref();
orderNumber.value = route.query.orderNumber; // 追加: URLからorderNumberクエリパラメータを取得
const status = ref();
const totalPrice = ref();
const vendorNameja = ref();

const getOrderdetail = async () => {
  const docRef = doc(db, "Goods_productorders", orderNumber.value);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    orderDetails.value = docSnap.data(); // ドキュメントのデータをorderDetailsに保存
    status.value = docSnap.data().status;
    totalPrice.value = docSnap.data().total_price;
    vendorNameja.value = docSnap.data().vendorname_ja;
  } else {
    // docSnap.data() will be undefined in this case
  }
};

getOrderdetail();
const orderDetailsSub = ref([]);

const getOrderdetailsub = async () => {
  const collectionRef = collection(db, "Goods_productorders", orderNumber.value, "orderproducts");
  const querySnapshot = await getDocs(collectionRef);

  querySnapshot.forEach((doc) => {
    orderDetailsSub.value.push(doc.data()); // ドキュメントのデータをorderDetailsSubに追加
  });
};

getOrderdetailsub();

const router = useRouter();
const toLink = (url) => {
  router.push(url);
};
</script>
<template>
  <v-app>
    <div class="maincontainer">
      <div class="title-container">
        <h2 class="title">限定グッズ注文履歴詳細</h2>
      </div>
      <div class="details-container">
        <p>
          注文番号：<span class="ordernumbertext">{{ orderNumber }}</span>
        </p>
        <p>配達状況：{{ status }}</p>
        <p>請求金額：{{ totalPrice }}</p>
        <p>配信者名：{{ vendorNameja }}</p>
      </div>
    </div>
    <div
      v-for="(order, index) in orderDetailsSub"
      :key="'order-' + index"
      class="product-container"
    >
      <div v-for="(product, pIndex) in order.products" :key="'product-' + pIndex">
        <div class="product-details">
          <div class="product-info">
            <div class="info-group">
              <div class="labels"><span class="underline">商品名:</span></div>
              <div class="values">{{ product.product_name }}</div>
            </div>
            <div class="info-group">
              <div class="labels"><span class="underline">ランク:</span></div>
              <div class="values">{{ product.product_rank_ja }}</div>
            </div>
            <div class="info-group">
              <div class="labels"><span class="underline">商品ID:</span></div>
              <div class="values">{{ product.productid }}</div>
            </div>
          </div>
          <img :src="product.product_image" class="product-image" alt="Product Image" />
        </div>
        <hr v-if="pIndex < order.products.length - 1" class="product-divider" />
      </div>
    </div>
    <div class="returncontainer" @click="toLink('orderhistorygoods')">
      <v-btn variant="outlined" height="30" width="65" rounded="pill" color="black" class="btn">
        <a class="return">戻る</a>
      </v-btn>
    </div>
  </v-app>
</template>

<style scoped>
.title-container {
  margin: 50px 50px;
}

.details-container {
  text-align: left;
  margin-left: 40px;
}

.product-container {
  border: 1px solid #000;
  padding: 20px;
  margin: 20px;
  border-radius: 15px;
  padding-bottom: 40px;
}

.product-details {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.product-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.info-group {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;
}

.labels {
  margin-right: 10px;
}

hr {
  border-top: 1px solid #000;
  margin-bottom: 20px;
}

.underline {
  white-space: nowrap;
  text-decoration: underline;
}

.product-image {
  width: 100px;
  height: auto;
}

.product-divider {
  border-top: 1px solid #000;
  margin: 20px 0;
}

.returncontainer {
  margin: 30px 0 0 0;
}

.return {
  color: rgb(142 144 147);
  font-size: 12px;
}

.ordernumbertext {
  font-size: 14px;
}
</style>
