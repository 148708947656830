<template>
  <v-app>
    <v-container>
      <v-row class="text-start">
        <v-col cols="12" class="text-center">
          <h2 class="mt-2">利用規約</h2>
        </v-col>
        <v-col cols="12">
          <v-list>
            <v-list-item-group>
              <v-list-item class="py-2">
                <v-list-item-content>
                  <p>
                    この利用規約（以下、「本規約」といいます。）は、株式会社ランダムファクトリー（以下、「当社」といいます。）
                    がこのウェブサイト上で提供するサービス（以下、「本サービス」といいます。）の利用条件を定めるものです。
                    登録ユーザーの皆さま（以下、「ユーザー」といいます。）には、本規約に従って、本サービスをご利用いただきます。
                  </p>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="mt-2">
                <v-list-item-content class="font-weight-bold">第1条（適用）</v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content
                  >本規約は，ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。</v-list-item-content
                >
              </v-list-item>

              <v-list-item class="mt-2">
                <v-list-item-content class="font-weight-bold"
                  >第2条（利用登録）</v-list-item-content
                >
              </v-list-item>

              <v-list-item>
                <v-list-item-content
                  >登録希望者が当社の定める方法によって利用登録を申請し、当社がこれを承認することによって，利用登録が完了するものとします。
                  当社は，利用登録の申請者に以下の事由があると判断した場合、利用登録の申請を承認しないことがあり、その理由については一切の開示義務を負わないものとします。
                  <li>利用登録の申請に際して虚偽の事項を届け出た場合</li>
                  <li>本規約に違反したことがある者からの申請である場合</li>
                  <li>その他、当社が利用登録を相当でないと判断した場合</li></v-list-item-content
                >
              </v-list-item>

              <v-list-item class="mt-2">
                <v-list-item-content class="font-weight-bold"
                  >第3条（ユーザーIDおよびパスワードの管理）</v-list-item-content
                >
              </v-list-item>

              <v-list-item>
                <v-list-item-content
                  >ユーザーは、自己の責任において、本サービスのユーザーIDおよびパスワードを管理するものとします。
                  ユーザーは、いかなる場合にも、ユーザーIDおよびパスワードを第三者に譲渡または貸与することはできません。
                  当社は、ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には、そのユーザーIDを登録しているユーザー自身による利用とみなします。</v-list-item-content
                >
              </v-list-item>

              <v-list-item class="mt-2">
                <v-list-item-content class="font-weight-bold"
                  >第4条（商品価格および支払方法）</v-list-item-content
                >
              </v-list-item>

              <v-list-item>
                <v-list-item-content
                  >ユーザーは、商品購入の対価として、本ウェブサイトに表示する価格にて、当社が指定する方法により支払うものとします。
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="mt-2">
                <v-list-item-content class="font-weight-bold"
                  >第5条（禁止事項）
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content
                  >ユーザーは、本サービスの利用にあたり、以下の行為をしてはなりません。
                  <li>法令または公序良俗に違反する行為</li>
                  <li>犯罪行為に関連する行為</li>
                  <li>当社のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為</li>
                  <li>当社のサービスの運営を妨害するおそれのある行為</li>
                  <li>他のユーザーに関する個人情報等を収集または蓄積する行為</li>
                  <li>他のユーザーに成りすます行為</li>
                  <li>
                    当社のサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為
                  </li>
                  <li>その他，当社が不適切と判断する行為</li></v-list-item-content
                >
              </v-list-item>

              <v-list-item class="mt-2">
                <v-list-item-content class="font-weight-bold"
                  >第6条（本サービスの提供の停止等）</v-list-item-content
                >
              </v-list-item>

              <v-list-item>
                <v-list-item-content
                  >当社は，以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
                  <li>本サービスにかかるコンピュータシステムの保守点検または更新を行う場合</li>
                  <li>
                    地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合
                  </li>
                  <li>コンピュータまたは通信回線等が事故により停止した場合</li>
                  <li>その他，当社が本サービスの提供が困難と判断した場合</li>
                  当社は，本サービスの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害について、理由を問わず一切の責任を負わないものとします。</v-list-item-content
                >
              </v-list-item>
              <v-list-item class="mt-2">
                <v-list-item-content class="font-weight-bold"
                  >第7条（利用制限および登録抹消）
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content
                  >当社は，以下の場合には、事前の通知なく，ユーザーに対して，本サービスの全部もしくは一部の利用を制限し，またはユーザーとしての登録を抹消することができるものとします。
                  <li>本規約のいずれかの条項に違反した場合</li>
                  <li>登録事項に虚偽の事実があることが判明した場合</li>
                  <li>その他，当社が本サービスの利用を適当でないと判断した場合</li>
                  当社は，本条に基づき当社が行った行為によりユーザーに生じた損害について、一切の責任を負いません。</v-list-item-content
                >
              </v-list-item>
              <v-list-item class="mt-2">
                <v-list-item-content class="font-weight-bold"
                  >第8条（免責事項）</v-list-item-content
                >
              </v-list-item>

              <v-list-item>
                <v-list-item-content
                  >当社の債務不履行責任は，当社の故意または重過失によらない場合には免責されるものとします。
                  当社は，何らかの理由によって責任を負う場合にも、通常生じうる損害の範囲内かつ商品購入金額の範囲内においてのみ賠償の責任を負うものとします。
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="mt-2">
                <v-list-item-content class="font-weight-bold"
                  >第9条（サービス内容の変更等）</v-list-item-content
                >
              </v-list-item>

              <v-list-item>
                <v-list-item-content
                  >当社は、ユーザーに通知することなく、本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし、これによってユーザーに生じた損害について一切の責任を負いません。
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="mt-2">
                <v-list-item-content class="font-weight-bold"
                  >第10条（利用規約の変更）</v-list-item-content
                >
              </v-list-item>

              <v-list-item>
                <v-list-item-content
                  >当社は，必要と判断した場合には、ユーザーに通知することなくいつでも本規約を変更することができるものとします。
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="mt-2">
                <v-list-item-content class="font-weight-bold"
                  >第11条（通知または連絡）</v-list-item-content
                >
              </v-list-item>

              <v-list-item>
                <v-list-item-content
                  >ユーザーと当社との間の通知または連絡は、当社の定める方法によって行うものとします。
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="mt-2">
                <v-list-item-content class="font-weight-bold"
                  >第12条（権利義務の譲渡の禁止）</v-list-item-content
                >
              </v-list-item>

              <v-list-item>
                <v-list-item-content
                  >ユーザーは，当社の書面による事前の承諾なく，利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し、または担保に供することはできません。
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="mt-2">
                <v-list-item-content class="font-weight-bold"
                  >第13条（準拠法・裁判管轄）</v-list-item-content
                >
              </v-list-item>

              <v-list-item>
                <v-list-item-content
                  >本規約の解釈にあたっては、日本法を準拠法とします。
                  本サービスに関して紛争が生じた場合には，東京地方裁判所裁判所を専属的合意管轄とします。<br /><br />
                  以上
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<style scoped>
.custom-content {
  line-height: 1.4; /* これにより行の高さが増加します */
}
</style>
