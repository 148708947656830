<script setup>
import { useRouter } from "vue-router";
import { ref } from "vue";
import EditPicture from "/src/components/molecules/EditPicture.vue";
import EditName from "/src/components/molecules/EditName.vue";
import db from "../main.js"; //追加
import { getFunctions, httpsCallable } from "firebase/functions";
import { doc, onSnapshot } from "firebase/firestore";

const props = defineProps({
  user: String,
});

const router = useRouter();
const pictureURL = ref(null);
const dialogname = ref(false);
const name = ref(null);

const gcsurl = ref(process.env.VUE_APP_GCS_URL);
const getProfile = async () => {
  onSnapshot(doc(db, "users", props.user.uid), (doc) => {
    pictureURL.value =
      gcsurl.value + "/live_station_images/" + props.user.uid + "/iconphoto_400x400.png";
    name.value = doc.data().name;
  });
};

getProfile();

const toLink = (url) => {
  router.push(url);
};
const dialog = ref(false);
const editPicture = () => {
  dialog.value = true;
};
const closedialog = () => {
  dialog.value = false;
  location.reload();
};
const closedialogwithoutchange = () => {
  dialog.value = false;
};
const editName = () => {
  dialogname.value = true;
};
const closedialogname = () => {
  dialogname.value = false;
};
const changename = async (nickname) => {
  const functions = getFunctions();
  functions.region = "asia-northeast1"; // これを追加
  const nicknameno = nickname.value;
  const addMessage = httpsCallable(functions, "Goods_ChangeName");
  await addMessage({ nickname: nicknameno }).then((res) => {
    console.log(res);
    closedialogname();
  });
};
</script>
<template>
  <v-app>
    <v-dialog v-model="dialog" width="unset"
      ><EditPicture
        :user="user"
        @closedialog="closedialog"
        @closedialogwithoutchange="closedialogwithoutchange"
    /></v-dialog>
    <v-dialog v-model="dialogname" width="unset"
      ><EditName :user="user" @closedialogname="closedialogname" @changename="changename"
    /></v-dialog>
    <div class="maincontainer">
      <div>
        <h2 class="mt-12 title">あなたのプロフィール</h2>
      </div>
      <div class="iconcontainer">
        <div class="icon"><img :src="pictureURL" /></div>
      </div>
      <div class="mt-2 text clickable-area" @click="editPicture">
        <v-icon>mdi-pencil</v-icon>プロフィール画像を変更
      </div>
      <div class="name">{{ name }}</div>
      <div class="text mt-2 clickable-area" @click="editName">
        <v-icon>mdi-pencil</v-icon>ユーザーネーム（ニックネーム）の変更
      </div>
    </div>
    <div class="returncontainer" @click="toLink('myaccount')">
      <v-btn variant="outlined" height="30" width="65" rounded="pill" color="black" class="btn">
        <a class="return">戻る</a>
      </v-btn>
    </div>
  </v-app>
</template>

<style scoped>
/* .form {
  width: 100%;
} */
.maincontainer {
  margin: 0 0 50px 0;
}
.title {
  font-size: 14px;
}
.text {
  font-size: 10px;
}
.name {
  border-bottom: solid;
  color: black;
  margin: 50px 120px 5px 120px;
  padding-bottom: 5px;
  border-bottom-width: thin;
}
.returncontainer {
  margin: 80px 0 0 0;
}
.btn {
  background-color: rgb(226, 226, 226);
  box-shadow: rgba(233, 233, 233, 0.9) 3px 3px 0px 0px;
}
.return {
  color: rgb(142 144 147);
  font-size: 12px;
}
.icon {
  width: 150px;
  height: 150px;
  margin-right: 5px;
  border-radius: 100px;
  overflow: hidden;
}
.icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.iconcontainer {
  margin: 20px 0 0 0;
  display: flex;
  /* align-items: center; */
  justify-content: center;
}
.clickable-area {
  cursor: pointer;
}
/*スマートフォン-----------------------------------------------------------------------------------*/
/*@media only screen and (max-width:768px){*/
@media only screen and (max-width: 1000px) {
  .login {
    color: rgb(255 255 255);
    font-size: 12px;
  }
}
</style>
