<script setup>
import { ref, computed, onMounted, onUnmounted } from "vue";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import BannerPicture from "/src/components/atoms/BannerPicture.vue";
import CampaignTitleBar from "/src/components/atoms/CampaignTitleBar.vue";
import SellInfo from "/src/components/atoms/SellInfo.vue";
import IndividualLiveSchedule from "/src/components/molecules/IndividualLiveSchedule.vue";
import GoodsList from "/src/components/molecules/GoodsList.vue";
import ItemRanking from "/src/components/molecules/ItemRanking.vue";
import { formatDateJaFull } from "@/utils/date.js";

import db from "../main.js"; //追加
import {
  collection,
  getDocs,
  onSnapshot,
  doc,
  query,
  where,
  limit,
  orderBy,
  getDoc,
} from "firebase/firestore";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();

const event_id = route.query.event_id;
const vtuber_image = ref(null);
const event_title = ref(null);
const eventdatestart = ref(null);
const eventdateend = ref(null);
const price = ref(null);
const shippingdate = ref(null);
const nowstreaming = ref(false);
const livedatefirst = ref();
const livedatesecond = ref();
const livedatethird = ref();
const livedatefourth = ref();
const livedateend = ref();
const totallivecount = ref();

dayjs.extend(customParseFormat); // フォーマット指定のためにプラグインを拡張

const getEventData = async () => {
  // const docRef = doc(db, "Goods_products", vendorname);
  onSnapshot(doc(db, "Goods_products", event_id), (doc) => {
    const data = doc.data();
    vtuber_image.value = data.vendor_image;
    event_title.value = data.eventtitle;
    eventdatestart.value = formatDateJaFull(data.eventdate.start.toDate()).replace(/:\d{2}$/, "");
    eventdateend.value = formatDateJaFull(data.eventdate.end.toDate()).replace(/:\d{2}$/, "");
    price.value = data.price;
    shippingdate.value = data.shipping_date;
    nowstreaming.value = data.nowstreaming;
    livedatefirst.value = data.livedate.first
      ? formatDateJaFull(data.livedate.first.toDate()).replace(/:\d{2}$/, "")
      : null;
    livedatesecond.value = data.livedate.second
      ? formatDateJaFull(data.livedate.second.toDate()).replace(/:\d{2}$/, "")
      : null;
    livedatethird.value = data.livedate.third
      ? formatDateJaFull(data.livedate.third.toDate()).replace(/:\d{2}$/, "")
      : null;
    livedatefourth.value = data.livedate.fourth
      ? formatDateJaFull(data.livedate.fourth.toDate()).replace(/:\d{2}$/, "")
      : null;
    livedateend.value = data.livedate.end
      ? formatDateJaFull(data.livedate.end.toDate()).replace(/:\d{2}$/, "")
      : null;
    totallivecount.value = data.totallivecount;

    console.log("Event end date:", eventdateend.value);
  });
};

getEventData();

// 現在時刻
const now = ref(dayjs());

// イベントが開始後1時間経ったかどうかの条件を計算
const isEventStartedAndElapsed = computed(() => {
  if (!eventdatestart.value) return false;
  const eventStartTime = dayjs(eventdatestart.value, "YYYY年MM月DD日HH:mm");
  return now.value.isAfter(eventStartTime.add(1, "hour"));
});

// イベントが終了したかどうかの条件を計算
const isEventend = computed(() => {
  if (!eventdateend.value) return false;

  // eventdateend.value が正しい形式であることを前提に、 dayjs オブジェクトを作成
  const eventEndTime = dayjs(eventdateend.value, "YYYY年MM月DD日HH:mm");

  // 現在時刻も dayjs オブジェクトに変換
  const currentTime = dayjs(now.value);

  console.log("Event end time:", eventEndTime);
  console.log("Current time:", currentTime);

  // 現在時刻がイベント終了時刻を過ぎているかを判定
  return currentTime.isAfter(eventEndTime);
});

// タイマーをセットアップ
let timer;

onMounted(() => {
  timer = setInterval(() => {
    now.value = dayjs();
  }, 1000); // 1秒ごとに更新
});

onUnmounted(() => {
  clearInterval(timer);
});

//当該ベンダーのサブコレクション取得
const goodsData = ref([]);
const getProductsData = async () => {
  const querySnapshot = await getDocs(collection(db, "Goods_products", event_id, "products"));
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    goodsData.value.push(data);
  });
};

getProductsData();

const toMain = () => {
  router.push(`main?event_id=${event_id}`);
  // this.$router.push(`/itemlist?vtubername=${card.vtuber_en}`, () => {});
};
const toArchive = () => {
  router.push(`archivelist?event_id=${event_id}`);
  // this.$router.push(`/itemlist?vtubername=${card.vtuber_en}`, () => {});
};

//ライブデータ
const liveData = ref([]);
const getLiveData = async () => {
  // Create a query against the collection.
  const q = query(collection(db, "Goods_liveshedule"), where("event_id", "==", event_id));
  // const querySnapshot = await getDocs(collection(db, "Goods_liveshedule"));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    liveData.value.push(data);
  });
};
getLiveData();

//point呼び出し
const rankingarray = ref([]);
// const rankingno1 = ref(null);
// const rankingno2 = ref(null);
// const rankingno3 = ref(null);
const getpoint = async () => {
  const querypoint = query(
    collection(db, "Goods_ranking", event_id, "ranking"),
    limit(3),
    orderBy("points", "desc")
  );
  // , where("capital", "==", true));
  onSnapshot(querypoint, (querySnapshot) => {
    rankingarray.value = [];
    // const rankingarray = ref([]);
    if (querySnapshot.empty) {
      const rankingarray = [];
      return rankingarray;
    }
    querySnapshot.forEach((doc) => {
      const setranking = doc.data();
      rankingarray.value.push(setranking);
    });
  });
};
getpoint();

//当選確率を取得

const winningRateSsr = ref("");
const winningRateR = ref("");
const winningRateA = ref("");
const winningRateB = ref("");
const winningRateC = ref("");
const getWinningRateData = async () => {
  try {
    const docRef = doc(db, "Goods_products", event_id, "rarity", "rarity");
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      const data = docSnapshot.data();
      console.log(data);
      winningRateSsr.value = (data.ssr * 100).toFixed(2);
      winningRateR.value = (data.r * 100).toFixed(2);
      winningRateA.value = (data.a * 100).toFixed(2);
      winningRateB.value = (data.b * 100).toFixed(2);
      winningRateC.value = (data.c * 100).toFixed(2);
      console.log("ooooooo" + winningRateSsr.value);
      console.log(winningRateR.value);
    } else {
      console.log("ドキュメントが見つかりません");
    }
  } catch (error) {
    console.error("データの取得中にエラーが発生しました:", error);
  }
};

getWinningRateData();
</script>
<template>
  <v-row>
    <v-col cols="12">
      <BannerPicture :vtuber_image="vtuber_image" />
    </v-col>

    <v-col>
      <CampaignTitleBar :event_title="event_title" />
    </v-col>

    <v-col cols="12">
      <SellInfo
        :eventdatestart="eventdatestart"
        :eventdateend="eventdateend"
        :price="price"
        :shippingdate="shippingdate"
        :livedatefirst="livedatefirst"
        :livedatesecond="livedatesecond"
        :livedatethird="livedatethird"
        :livedatefourth="livedatefourth"
        :livedateend="livedateend"
        :totallivecount="totallivecount"
      />
    </v-col>

    <v-col cols="12" v-if="nowstreaming">
      <h3 class="title boxtitle" @click="toMain">配信を見る</h3>
    </v-col>
    <v-col cols="12" v-if="!nowstreaming && !isEventStartedAndElapsed">
      <h3 class="title boxtitlebefore">販売開始前</h3>
      <p class="attention">※配信が開始されましたら上のボタンより配信画面に入室できます</p>
    </v-col>

    <!-- イベントが開始されていて、かつ、配信中でない場合 -->
    <v-col cols="12" v-if="!nowstreaming && isEventStartedAndElapsed && !isEventend">
      <h3 class="title boxtitlebefore" @click="toArchive">アーカイブを見る（時間外購入）</h3>
      <p class="attention">※ライブ配信中は、上のボタンが「配信を見る」に変更され入室できます</p>
    </v-col>

    <v-col cols="12" v-if="!nowstreaming && isEventend">
      <h3 class="title boxtitlebefore" @click="toArchive">販売終了（アーカイブを見る）</h3>
      <p class="attention">※このキャンペーンは販売終了したため、購入はできません。</p>
    </v-col>

    <v-col cols="12">
      <h3 class="title">配信スケジュール</h3>
      <IndividualLiveSchedule :liveData="liveData" />
    </v-col>

    <v-col cols="12">
      <h3 class="title">グッズ一覧</h3>
      <GoodsList
        :goodsData="goodsData"
        :winningRateSsr="winningRateSsr"
        :winningRateR="winningRateR"
        :winningRateA="winningRateA"
        :winningRateB="winningRateB"
        :winningRateC="winningRateC"
      />
    </v-col>

    <v-col cols="12">
      <h3 class="title">ランキング</h3>
      <ItemRanking :rankingarray="rankingarray" :rankingGoodsData="rankingGoodsData" />
    </v-col>
  </v-row>
</template>

<style scoped>
.title {
  margin: 40px 0 30px 0;
}
.boxtitle {
  border: solid 1px rgb(218, 57, 144);
  display: inline-block;
  padding: 15px 15px 15px 15px;
  background-color: rgb(218, 57, 144);
  border-radius: 20px 20px 20px 20px;
  color: white;
  cursor: pointer;

  /* text-align: left; */
}
.boxtitlebefore {
  border: solid 1px rgb(204, 204, 204);
  display: inline-block;
  padding: 15px 15px 15px 15px;
  background-color: rgb(204, 204, 204);
  border-radius: 20px 20px 20px 20px;
  color: white;

  /* text-align: left; */
}
.attention {
  margin: 0px 5px 0px 5px;
}
</style>
