<script setup>
import { ref } from "vue";
import { getFunctions, httpsCallable } from "firebase/functions";

const name = ref("");
const email = ref("");
const message = ref("");
const showSuccessMessage = ref(false);

async function submitMailconfirm() {
  const nameValue = name.value;
  const emailValue = email.value;
  const messageValue = message.value;

  const functions = getFunctions();
  functions.region = "asia-northeast1";

  const commonContactMail = httpsCallable(functions, "Common_confirmMail");

  try {
    const result = await commonContactMail({
      name: nameValue,
      email: emailValue,
      message: messageValue,
    });
    console.log(result);
    showSuccessMessage.value = true;
  } catch (error) {
    console.error("Error calling function:", error);
  }
}
</script>

<template>
  <v-app>
    <div class="maincontainer">
      <div>
        <h2 class="mt-12 title">メールアドレス確認のお願い</h2>
      </div>
      <div class="mt-12">
        <p>
          登録のメールアドレスにお送りしているメールのリンクをクリックの上、メールアドレスを確認してください。
        </p>
      </div>
      <div>
        <p>
          メールの再送は<span @click="submitMailconfirm" style="cursor: pointer; color: blue"
            >こちらをクリックしてください</span
          >
        </p>
      </div>
      <div class="message" v-if="showSuccessMessage">
        <p>メールを再送しました。</p>
      </div>
    </div>
  </v-app>
</template>

<style scoped>
/* .form {
  width: 100%;
} */
.maincontainer {
  margin: 0 20px 50px 20px;
}
.title {
  font-size: 14px;
}
.message {
  color: red;
  font-size: 15px;
  margin: 14px 0 0 0;
}

/*スマートフォン-----------------------------------------------------------------------------------*/
/*@media only screen and (max-width:768px){*/
@media only screen and (max-width: 1000px) {
  .login {
    color: rgb(255 255 255);
    font-size: 12px;
  }
}
</style>
