<script setup>
import { ref } from "vue";
import { getAuth, createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useRouter } from "vue-router";

const valid = ref(false);
const email = ref(null);
const nickname = ref(null);
const emailRules = [
  (v) => !!v || "メールアドレスを入力してください",
  (v) => /.+@.+\..+/.test(v) || "メールアドレスを入力してください",
];
const password = ref(null);
const show = ref(false);
const text = ref(null);

const loadingforsignup = ref(false);

const signUp = async () => {
  loadingforsignup.value = true;
  const auth = getAuth();
  // ニックネームの入力制限チェック
  if (nickname.value.length < 2 || nickname.value.length > 10) {
    text.value = "ニックネームは2文字以上10文字以下で入力してください。";
    return;
  }

  createUserWithEmailAndPassword(auth, email.value, password.value)
    .then((userCredential) => {
      updateProfile(auth.currentUser, {
        displayName: nickname.value,
      })
        .then(async () => {
          const functions = getFunctions();
          functions.region = "asia-northeast1"; // これを追加
          const addMessage = httpsCallable(functions, "Common_setFirstUserName");
          await addMessage({ name: nickname.value })
            .then((result) => {
              loadingforsignup.value = false;
              console.log(result);
              toLink("/twofactorauthenticationpin");
            })
            .catch((error) => {
              loadingforsignup.value = false;
              const errorCode = error.code;
              const errorMessage = error.message;
              console.log(errorCode);
              console.log(errorMessage);
              console.log(error); // eslint-disable-line no-console
              // An error occurred
              // ...
            });
          // Signed in
          const user = userCredential.user;
          console.log(user);
          // ...
        })
        .catch((error) => {
          loadingforsignup.value = false;
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode);
          console.log(errorMessage);
          console.log(error);
        });
    })
    .catch((error) => {
      loadingforsignup.value = false;
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode);
      console.log(errorMessage);
      console.log(error); // eslint-disable-line no-console
      if (error.code === "auth/email-already-in-use") {
        text.value = "このメールアドレスは既に使われています";
      } else if (error.code === "auth/invalid-email") {
        text.value = "メールアドレスの形式をご確認ください";
      } else if (error.code === "auth/weak-password") {
        text.value = "パスワードが短すぎます。再度登録をしてください。";
      } else {
        text.value = "ユーザー名、メールアドレス、パスワードを確認の上、再度登録をしてください";
      }

      // ..
    });
};
const router = useRouter();
const toLink = (url) => {
  router.push(url);
};
const deletemessage = () => {
  text.value = null;
};

const nicknameRules = [
  (v) => !!v || "ユーザー名（ニックネーム）を入力してください。",
  (v) => (v && v.length >= 2) || "ユーザー名（ニックネーム）は2文字以上である必要があります。",
  (v) => (v && v.length <= 10) || "ユーザー名（ニックネーム）は10文字以下である必要があります。",
];

const passwordRules = [
  (v) => !!v || "パスワードを入力してください",
  (v) => (v && v.length >= 6) || "パスワードは6文字以上である必要があります。",
];
</script>
<template>
  <v-app>
    <div v-if="loadingforsignup" class="loading-overlay">
      <div class="loading-message">登録中<span class="loading-dots"></span></div>
    </div>
    <div class="maincontainer">
      <div>
        <h2 class="mt-12 title">SukiSukiアカウント 新規登録</h2>
      </div>
      <div class="mt-12">
        <v-form ref="form" v-model="valid" class="form">
          <div>
            <div class="mailaddress">ユーザー名（ニックネーム）</div>
            <v-text-field
              class="ml-10 mr-10"
              v-model="nickname"
              :rules="nicknameRules"
              density="compact"
              required
              @click="deletemessage"
            />
          </div>
          <div>
            <div class="mt-5 mailaddress">メールアドレス</div>
            <v-text-field
              class="ml-10 mr-10"
              v-model="email"
              :rules="emailRules"
              density="compact"
              required
              @click="deletemessage"
            />
          </div>
          <div>
            <div class="mt-5 mailaddress">パスワードを入力</div>
            <v-text-field
              class="ml-10 mr-10"
              v-model="password"
              :rules="passwordRules"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              @click:append="() => (show = !show)"
              density="compact"
              required
              @click="deletemessage"
            />
          </div>
          <div class="message">
            {{ text }}
          </div>
          <div>
            <v-btn
              :disabled="!valid"
              height="35"
              width="100"
              rounded="pill"
              @click="signUp()"
              color="rgb(237, 121, 149)"
              class="mt-5"
            >
              <a class="login">新規登録</a>
            </v-btn>
          </div>
        </v-form>
      </div>
      <div class="resetpasswordcontainer">
        <a class="resetpassword" @click="toLink('login')">
          ▶︎既にSukiSukiアカウントをお持ちの方はこちら
        </a>
      </div>
    </div>
  </v-app>
</template>

<style scoped>
/* .form {
  width: 100%;
} */
.maincontainer {
  margin: 0 0 50px 0;
}
.title {
  font-size: 14px;
}
.login {
  color: rgb(255 255 255);
  font-size: 12px;
}
.resetpassword {
  font-size: 10px;
  text-decoration: underline;
}
.resetpasswordcontainer {
  margin: 8px 0 0 0;
}
.signup {
  color: rgb(255 255 255 100%);
  font-size: 12px;
}
.mailaddress {
  font-size: 12px;
  text-align: left;
  margin-left: 40px;
}
.message {
  color: red;
  font-size: 10px;
  margin: 10px 0 0 0;
}

/*スマートフォン-----------------------------------------------------------------------------------*/
/*@media only screen and (max-width:768px){*/
@media only screen and (max-width: 1000px) {
  .login {
    color: rgb(255 255 255);
    font-size: 12px;
  }
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.5); /* 半透明の灰色背景色 */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* 追加：z-indexプロパティ */
}
@keyframes ellipsis {
  0% {
    content: " .  ";
  }
  33% {
    content: " .. ";
  }
  67% {
    content: " ...";
  }
  100% {
    content: "    ";
  }
}

.loading-message {
  color: white; /* 白色の文字色 */
  font-size: 24px;
  font-weight: bold;
}

.loading-dots::after {
  content: "";
  display: inline-block;
  width: 1em;
  animation: ellipsis 2s infinite;
  color: white; /* 白色の文字色 */
}
</style>
