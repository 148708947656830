<script setup>
import { ref } from "vue";
import { getStorage, ref as storageref, uploadBytes } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";
import { readAndCompressImage } from "browser-image-resizer";
const props = defineProps({
  user: String,
});
const emit = defineEmits(["closedialog", "closedialogwithoutchange"]);
const pictureURL = ref(null);
// データのアップロード中であることを示す変数
const isUploading = ref(false);
//環境変数
const gcsurl = ref(process.env.VUE_APP_GCS_URL);
const getpictureURLPreview = () => {
  pictureURL.value =
    gcsurl.value + "/live_station_images/" + props.user.uid + "/iconphoto_400x400.png";
};
getpictureURLPreview();

const iconpic = ref();
const previewpicurl = ref();

const previewPic = () => {
  previewpicurl.value = URL.createObjectURL(iconpic.value.files[0]);
};

async function resizeImage(file, maxWidth, maxHeight) {
  const config = {
    maxWidth: maxWidth,
    maxHeight: maxHeight,
    quality: 0.9,
    autoRotate: true,
  };

  try {
    const resizedImage = await readAndCompressImage(file, config);
    return resizedImage;
  } catch (error) {
    console.error("Error resizing image:", error);
    throw error;
  }
}

const sendaddpicture = async () => {
  isUploading.value = true; // アップロード開始

  const iconpicupload800 = "live_station_images/" + props.user.uid + "/iconphoto_800x800.png";
  const iconpicupload400 = "live_station_images/" + props.user.uid + "/iconphoto_400x400.png";
  const iconpicuploadOriginal = "live_station_images/" + props.user.uid + "/iconphoto.png";
  const metadata = {
    cacheControl: "no-cache, no-store, must-revalidate",
    pragma: "no-cache",
  };
  const storage = getStorage();
  const storageRef800 = storageref(storage, iconpicupload800);
  const storageRef400 = storageref(storage, iconpicupload400);
  const storageRefOriginal = storageref(storage, iconpicuploadOriginal);

  try {
    const resizedImage800 = await resizeImage(iconpic.value.files[0], 800, 800);
    const resizedImage400 = await resizeImage(iconpic.value.files[0], 400, 400);
    await uploadBytes(storageRef800, resizedImage800, metadata);
    await uploadBytes(storageRef400, resizedImage400, metadata);
    await uploadBytes(storageRefOriginal, iconpic.value.files[0], metadata);
  } catch (error) {
    console.error("Error uploading resized and original images:", error);
    isUploading.value = false; // アップロード失敗
    return;
  }

  const functions = getFunctions();
  functions.region = "asia-northeast1";
  const iconpicuploadurl =
    gcsurl.value + "/live_station_images/" + props.user.uid + "/iconphoto.png";

  const myCallableFunction = httpsCallable(functions, "Goods_AddIconpic");
  myCallableFunction({ iconpic: iconpicuploadurl })
    .then((result) => {
      console.log(result);
      emit("closedialog");
      isUploading.value = false; // アップロード成功
    })
    .catch((error) => {
      console.log(error.message);
      isUploading.value = false; // アップロード失敗
    });
};

const closedialogwithoutchange = () => {
  emit("closedialogwithoutchange");
};
</script>

<template div="maincontainer">
  <v-row class="dialogtitle pt-5 pb-5" height="100px" justify="center"
    >プロフィール写真を変更</v-row
  >
  <div class="background">
    <label>
      <div class="iconcontainer">
        <input type="file" accept="image/jpeg, image/png" ref="iconpic" @change="previewPic" />
        <div class="icon">
          <img v-if="previewpicurl" :src="previewpicurl" />
          <img v-else :src="pictureURL" />
        </div>
      </div>
    </label>
    <p class="ml-3 mt-5 dis">※プロフィール写真を変更は、上の画像をタップしてください</p>

    <div class="bottonconteiner">
      <div class="">
        <v-btn
          class="ml-13"
          height="30"
          width="80"
          rounded="pill"
          color="rgba(226, 226, 226)"
          v-bind:disabled="isUploading"
        >
          <a class="return" @click="closedialogwithoutchange">キャンセル</a>
        </v-btn>
      </div>
      <div>
        <v-btn
          class="ml-5"
          height="30"
          width="80"
          rounded="pill"
          @click="sendaddpicture()"
          color="rgb(231, 192, 255)"
          v-bind:disabled="isUploading"
        >
          <a class="addaddress">保存する</a>
        </v-btn>
      </div>
    </div>
  </div>
  <div v-if="isUploading" class="uploading-indicator">
    <v-progress-circular indeterminate></v-progress-circular>
    <span>画像をアップロード中...</span>
  </div>
</template>

<style scoped>
.field {
  margin: 20px 0 0 20px;
}

.inputpostcode {
  font-size: 10px;
  margin: 0 0 0 0px;
  outline: 1px solid;
  padding: 5px 5px 5px 5px;
  width: 100px;
  height: 20px;
}
.inputtodohuken {
  font-size: 10px;
  margin: 0 0 0 0px;
  outline: 1px solid;
  padding: 5px 5px 5px 5px;
  width: 100px;
  height: 20px;
}
.inputaddress {
  font-size: 10px;
  margin: 0 0 0 0px;
  outline: 1px solid;
  padding: 5px 5px 5px 5px;
  width: 250px;
  height: 20px;
}

.addcard {
  font-size: 12px;
}

.dialogtitle {
  margin: 0 0 0 0;
  /* padding: 0.5em 1em; */
  background: #fff;
  border: solid 1px;
  border-radius: 10px 10px 0 0; /*角の丸み*/
  background-color: rgb(142 144 147);
}

.background {
  background-color: white;
  width: 300px;
  border: solid 1px;
  border-radius: 0px 0px 10px 10px; /*角の丸み*/
}
.inputname {
  font-size: 10px;
  margin: 0 0 0 0px;
  outline: 1px solid;
  padding: 5px 5px 5px 5px;
  width: 150px;
  height: 20px;
}
.bottonconteiner {
  margin: 50px 0 20px 0;
  display: flex;
}
.addaddress {
  color: rgb(255 255 255);
  font-size: 10px;
}
.returncontainer {
  margin: 100px 0 0 0;
}
.return {
  color: rgb(142 144 147);
  font-size: 12px;
}
.icon {
  width: 200px;
  height: 200px;
  margin-right: 5px;
  border-radius: 100px;
  overflow: hidden;
}
.icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.iconcontainer {
  margin: 30px 0 0 0;
  display: flex;
  /* align-items: center; */
  justify-content: center;
}
input[type="file"] {
  display: none;
}
uploading-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  z-index: 100;
}
.dis {
  font-size: 10px;
}
</style>
