<template>
  <v-app>
    <v-container>
      <v-row class="text-start">
        <v-col cols="12" class="text-center">
          <h2 class="mt-2">会社概要</h2>
        </v-col>
        <v-col cols="12">
          <v-list>
            <v-list-item-group>
              <v-list-item class="mt-2">
                <v-list-item-content class="font-weight-bold">会社名</v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>株式会社ランダムファクトリー</v-list-item-content>
              </v-list-item>

              <v-list-item class="mt-2">
                <v-list-item-content class="font-weight-bold">所在地</v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content
                  >東京都渋谷区道玄坂1丁目10番8号 渋谷道玄坂東急ビル2F−C</v-list-item-content
                >
              </v-list-item>
              <v-list-item class="mt-2">
                <v-list-item-content class="font-weight-bold">役員</v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content
                  ><p>代表取締役CEO 伊奈守道</p>
                  <p>取締役CTO 宮川健吾</p>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="mt-2">
                <v-list-item-content class="font-weight-bold">電話</v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>03-6774-7991</v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<style scoped>
.custom-content {
  line-height: 1.4; /* これにより行の高さが増加します */
}
</style>
