<script setup>
import { ref } from "vue";
import db from "../main.js"; //追加
import { collection, query, where, getDocs } from "firebase/firestore";
import VendorPictures from "/src/components/molecules/VendorPictures.vue";
import LiveSchedule from "/src/components/molecules/LiveSchedule.vue";
import TheCarousel from "/src/components/molecules/TheCarousel.vue";

//イベントデータ
const productsData = ref([]);
const getProductsData = async () => {
  // クエリオブジェクトを作成
  const q = query(collection(db, "Goods_products"), where("eventcategory", "==", "vtuber"));

  // クエリに基づいてドキュメントを取得
  const querySnapshot = await getDocs(q);

  // 取得したドキュメントのデータをproductsDataに格納
  productsData.value = querySnapshot.docs.map((doc) => doc.data());
};

// この関数を呼び出してデータを取得
getProductsData();

//ライブデータ
const liveData = ref([]);
const getLiveData = async () => {
  console.log("livesss");
  // Goods_liveschedule コレクションから eventcategory が vtuber であるドキュメントをクエリする
  const q = query(collection(db, "Goods_liveshedule"), where("eventcategory", "==", "vtuber"));

  // クエリに基づいてドキュメントを取得
  const querySnapshot = await getDocs(q);

  // 取得したドキュメントのデータを liveData に格納
  liveData.value = querySnapshot.docs.map((doc) => doc.data());
  console.log(liveData);
};

// 関数を呼び出してデータを取得
getLiveData();
</script>

<template>
  <TheCarousel :productsData="productsData" />
  <h2 class="title">配信</h2>
  <LiveSchedule :liveData="liveData" />
  <h2 class="title">イベント</h2>
  <VendorPictures :productsData="productsData" />
</template>

<style scoped>
.title {
  margin: 40px 0 40px 0px;
}
</style>
