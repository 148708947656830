<script setup>
import { toRefs } from "vue";

const props = defineProps({
  eventdatestart: String,
  eventdateend: String,
  price: String,
  shippingdate: String,
  livedatefirst: String,
  livedatesecond: String,
  livedatethird: String,
  livedatefourth: String,
  livedateend: String,
  totallivecount: Number,
});

const {
  eventdatestart,
  eventdateend,
  price,
  shippingdate,
  livedatefirst,
  livedatesecond,
  livedatethird,
  livedatefourth,
  livedateend,
} = toRefs(props);
</script>

<template>
  <v-container class="pa-0 mt-5">
    <v-row no-gutters>
      <v-col cols="12" md="8" offset-md="2">
        <div class="box d-flex flex-column">
          <v-row class="align-center">
            <v-col class="d-flex align-center pr-1" cols="1"
              ><img class="icon" src="img/time.png"
            /></v-col>
            <v-col class="d-flex align-center pl-1" cols="10">
              <p>イベント開始：{{ eventdatestart }}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex align-center pl-1 offset-1" cols="10">
              <p>イベント終了：{{ eventdateend }}</p>
            </v-col>
          </v-row>

          <v-row class="align-center mt-2">
            <v-col class="d-flex align-center pr-1" cols="1"
              ><img class="icon" src="img/price.png"
            /></v-col>
            <v-col class="d-flex align-center pl-1" cols="10">
              <p>グッズ販売価格：１回{{ price }}円（税込）</p>
            </v-col>
          </v-row>
          <v-row class="align-center mt-2">
            <v-col class="d-flex align-center pr-1" cols="1"
              ><img class="icon" src="img/shipping.png"
            /></v-col>
            <v-col class="d-flex align-center pl-1" cols="10">
              <p>送料：550円（税込・20回毎にかかります）</p>
            </v-col>
          </v-row>
          <v-row class="align-center mt-2">
            <v-col class="d-flex align-center pr-1" cols="1"
              ><img class="icon" src="img/otodoke.png"
            /></v-col>
            <v-col class="d-flex align-center pl-1" cols="10">
              <p>お届け目安：{{ shippingdate }}</p>
            </v-col>
          </v-row>
          <v-row class="align-center mt-2">
            <v-col class="d-flex align-center pr-1" cols="1"
              ><img class="icon" src="img/otodoke.png"
            /></v-col>
            <v-col class="d-flex align-center pl-1" cols="10">
              <p>初回配信日時：{{ livedatefirst }}</p>
            </v-col>
          </v-row>
          <v-row v-if="livedatesecond">
            <v-col class="d-flex align-center pl-1 offset-1" cols="10">
              <p>2回目配信日時：{{ livedatesecond }}</p>
            </v-col>
          </v-row>
          <v-row v-if="livedatethird">
            <v-col class="d-flex align-center pl-1 offset-1" cols="10">
              <p>3回目配信日時：{{ livedatethird }}</p>
            </v-col>
          </v-row>
          <v-row v-if="livedatefourth">
            <v-col class="d-flex align-center pl-1 offset-1" cols="10">
              <p>4回目配信日時：{{ livedatefourth }}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex align-center pl-1 offset-1" cols="10">
              <p>最終回配信日時：{{ livedateend }}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex align-center pl-1 offset-1" cols="10">
              <p>※配信回数：{{ totallivecount }}回</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex align-center pl-1 offset-1 mt-5" cols="10">
              <p>※イベント開始は初回配信開始時日時、イベント終了は最終回配信終了日時となります</p>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.box {
  border: 1px solid #000;
  background-color: white;
  padding: 16px;
  text-align: left;
}
.icon {
  width: 20px;
}
@media only screen and (max-width: 768px) {
  .box {
    margin-left: 30px;
    margin-right: 30px;
    font-size: 12px;
  }
  .icon {
    width: 16px; /* アイコンサイズを小さくする */
  }
}
</style>
