<template>
  <div class="movie">
    <div class="movie_inner">
      <video
        ref="videoElement"
        :autoplay="true"
        :muted="isMuted"
        :loop="true"
        playsinline
        height="100%"
        @canplaythrough="onCanPlayThrough"
        @error="onError"
        @timeupdate="onTimeUpdate"
        @loadedmetadata="onLoadedMetadata"
      ></video>
      <div v-if="duration > 0" class="controls">
        <span class="time-display">{{ formattedCurrentTime }}</span>
        <input
          type="range"
          class="custom-slider"
          min="0"
          :max="duration"
          step="0.01"
          v-model="currentTime"
          @input="onSeek"
        />
        <span class="time-display">{{ formattedDuration }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineProps, nextTick, onMounted, computed, defineEmits } from "vue";
import Hls from "hls.js";

const props = defineProps({
  videourl: {
    type: String,
    default: null,
  },
  videourlforondemand: {
    type: String,
    default: null,
  },
  isMuted: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(["timeupdate"]);

const videoElement = ref(null);
const currentTime = ref(0);
const duration = ref(0);
let hls = null;

const isHls = (url) => {
  console.log(`Checking if URL is HLS: ${url}`);
  return url && (url.endsWith(".m3u8") || url.includes(".m3u8?"));
};

const loadVideo = (url) => {
  console.log(`Attempting to load video from URL: ${url}`);
  if (hls) {
    hls.destroy();
    hls = null;
  }

  if (url) {
    if (isHls(url)) {
      console.log("HLS stream detected");
      if (Hls.isSupported()) {
        hls = new Hls();
        hls.attachMedia(videoElement.value);
        hls.on(Hls.Events.MEDIA_ATTACHED, () => {
          console.log("HLS media attached");
          hls.loadSource(url);
          hls.on(Hls.Events.MANIFEST_PARSED, () => {
            console.log("HLS manifest loaded, attempting to play the video");
            videoElement.value.play().catch((error) => {
              console.error(`Failed to play video: ${error}`);
            });
          });
        });
        hls.on(Hls.Events.ERROR, (event, data) => {
          console.error(`HLS error: ${data.type}`, data);
        });
      } else {
        console.warn("HLS not supported by this browser");
        videoElement.value.src = url;
      }
    } else {
      console.log("MP4 file detected");
      videoElement.value.src = url;
      videoElement.value.addEventListener("loadedmetadata", () => {
        console.log("Metadata loaded, attempting to play the video");
        videoElement.value.play().catch((error) => {
          console.error(`Failed to play video: ${error}`);
        });
      });
    }
  } else {
    console.warn("No valid video URL provided.");
  }
};

const onCanPlayThrough = () => {
  console.log("Video can play through without interruption");
  if (videoElement.value) {
    videoElement.value.play();
  }
};

const onError = (event) => {
  console.error("Failed to load video:", event);
  console.error("Error event details:", event);
  console.error("Failed URL:", videoElement.value.src);
};

const determineVideoUrl = () => {
  console.log(
    `determineVideoUrl - videourl: ${props.videourl}, videourlforondemand: ${props.videourlforondemand}`
  );
  if (props.videourl) {
    return props.videourl;
  } else if (props.videourlforondemand) {
    return props.videourlforondemand;
  } else {
    return null;
  }
};

const updateVideoSource = () => {
  nextTick(() => {
    const url = determineVideoUrl();
    console.log(`Determined URL: ${url}`);
    if (url) {
      loadVideo(url);
    } else {
      console.warn("Both videourl and videourlforondemand are null or invalid.");
    }
  });
};

const onTimeUpdate = () => {
  if (videoElement.value) {
    currentTime.value = videoElement.value.currentTime;
    emit("timeupdate", currentTime); // 親コンポーネントに送信
  }
};

const onLoadedMetadata = () => {
  if (videoElement.value) {
    duration.value = videoElement.value.duration;
  }
};

const onSeek = () => {
  if (videoElement.value) {
    videoElement.value.currentTime = currentTime.value;
  }
};

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
};

const formattedCurrentTime = computed(() => formatTime(currentTime.value));
const formattedDuration = computed(() => formatTime(duration.value));
watch(
  () => props.videourlforondemand,
  (newUrl) => {
    console.log("Props changed: videourlforondemand", newUrl);
    if (newUrl) {
      updateVideoSource();
    }
  }
);

onMounted(() => {
  updateVideoSource();
});
</script>

<style scoped>
.movie {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black; /* 必要に応じて背景色を設定 */
}
.movie .movie_inner {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.movie .movie_inner video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
}
.controls {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.custom-slider {
  flex: 1;
  -webkit-appearance: none; /* Remove default style */
  appearance: none;
  height: 8px;
  background: #ddd;
  border-radius: 4px;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
  z-index: 5; /* z-indexを追加 */
}
.custom-slider:hover {
  opacity: 1;
}
.custom-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: rgb(255, 255, 255);
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  transform: translateY(-5px); /* つまみの位置を少し上に移動 */
}
.custom-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #4caf50;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  transform: translateY(-5px); /* つまみの位置を少し上に移動 */
}
.custom-slider::-ms-thumb {
  width: 20px;
  height: 20px;
  background: #4caf50;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  transform: translateY(-5px); /* つまみの位置を少し上に移動 */
}
.custom-slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  background: #ddd;
  border-radius: 4px;
}
.custom-slider::-moz-range-track {
  width: 100%;
  height: 8px;
  background: #ddd;
  border-radius: 4px;
}
.custom-slider::-ms-track {
  width: 100%;
  height: 8px;
  background: transparent;
  border-color: transparent;
  border-width: 8px 0;
  color: transparent;
}
.custom-slider::-ms-fill-lower {
  background: #ddd;
  border-radius: 4px;
}
.custom-slider::-ms-fill-upper {
  background: #ddd;
  border-radius: 4px;
}
.time-display {
  color: white;
  font-size: 14px;
  min-width: 50px;
  text-align: center;
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.5)); /* ドロップシャドウを追加 */
}
@media (min-width: 960px) {
  .movie {
    width: auto;
    height: 80vh;
  }
  .movie .movie_inner {
    width: auto;
    height: 80vh;
    overflow: hidden;
  }
  .vjs_video_3-dimensions {
    width: auto;
    height: 80vh;
  }
  .movie .movie_inner video {
    position: initial;
    width: auto;
    height: 80vh;
    object-fit: cover;
  }
}
/* スマホ用のスタイル */
@media (max-width: 960px) {
  .custom-slider {
    margin-bottom: 20px; /* シークバーの位置を上に上げるためのマージンを追加 */
    transform: translateY(-10px); /* つまみの位置をさらに上に移動 */
  }

  .controls {
    bottom: 30px; /* スマホでのシークバー全体をもう少し上に配置 */
  }
  .time-display {
    transform: translateY(-23px); /* 時間表示も同じように上に移動 */
  }
  .custom-slider {
    height: 13px; /* スマホの場合にシークバーを太く */
  }
}
</style>
