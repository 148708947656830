<script setup>
import { toRefs, computed } from "vue";
import { useRouter } from "vue-router";
import { formatDateJaFull } from "@/utils/date.js";

const props = defineProps({
  liveData: Array,
});

const { liveData } = toRefs(props);
const router = useRouter();
const liveproductsData = computed(() => {
  return liveData.value.filter(function (liveData) {
    return liveData.live_status === "live";
  });
});

const liveproductsDatainprogress = computed(() => {
  return liveData.value.filter(function (liveData) {
    return liveData.live_status === "inprogress";
  });
});

// const router = useRouter();

const toItemlist = (productsData) => {
  router.push(`itemlist_v?event_id=${productsData.event_id}`);
  // this.$router.push(`/itemlist?vtubername=${card.vtuber_en}`, () => {});
};
const toMain = (productsData) => {
  router.push(`main?event_id=${productsData.event_id}`);
  // this.$router.push(`/itemlist?vtubername=${card.vtuber_en}`, () => {});
};
</script>
<template>
  <div>
    <!-- Live products section -->
    <v-row class="pc-ma-2 cancelrow" justify="center">
      <v-col
        v-for="(productsData, index) in liveproductsData"
        :key="index"
        cols="12"
        sm="6"
        md="4"
        class="cancelpadding"
      >
        <!-- Image -->
        <v-img
          class="clickable-area"
          :src="productsData.vendor_image"
          @click="toMain(productsData)"
        />

        <!-- Card content -->
        <v-card class="custom-card">
          <div class="livecontainer clickable-area pa-2" @click="toMain(productsData)">
            <div class="livestatus mb-2">
              <p>配信中</p>
            </div>
            <h3 class="title mb-2">{{ productsData.eventtitle }}</h3>
            <p class="title mb-2">{{ productsData.livenumber }}</p>
            <p class="time mb-2">
              <v-icon size="20">mdi-timer</v-icon>
              {{ formatDateJaFull(productsData.livedate.end.toDate()).replace(/:\d{2}$/, "") }}まで
            </p>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <!-- Live products in progress section -->
    <v-row class="pc-ma-2" justify="center">
      <v-col
        v-for="(productsData, index) in liveproductsDatainprogress"
        :key="index"
        cols="12"
        sm="6"
        md="4"
        class="cancelpadding"
      >
        <!-- Image -->
        <v-img
          class="cursor mb-2 clickable-area"
          :src="productsData.vendor_image"
          @click="toItemlist(productsData)"
        />

        <!-- Card content -->
        <v-card class="custom-card" color="white">
          <div class="livecontainer pa-2 clickable-area" @click="toItemlist(productsData)">
            <div class="livestatusinprogress mb-2">
              <p>配信予定</p>
            </div>
            <h3 class="title mb-2">{{ productsData.eventtitle }}</h3>
            <p class="title mb-2">{{ productsData.livenumber }}</p>
            <p class="time mb-2">
              <v-icon size="20">mdi-timer</v-icon>
              {{
                formatDateJaFull(productsData.livedate.start.toDate()).replace(/:\d{2}$/, "")
              }}から
            </p>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.maincontainer {
  margin: 10px 0 15px 0;
}

.livecontainer {
  border: solid 1px #454545;
  background-color: rgb(255, 254, 245);
}
.livestatus {
  border: solid 1px rgb(240, 172, 196);
  display: inline-block;
  float: left;
  margin: 15px 0 5px 20px;
  background-color: rgb(240, 172, 196);
  color: white;
  padding: 5px 5px 5px 5px;
  font-size: 12px;
}
.title {
  clear: both;
  margin: 5px 0 5px 20px;
  text-align: left;
}
.time {
  margin: 5px 0 15px 20px;
  text-align: left;
}
.livestatusinprogress {
  border: solid 1px rgb(152, 45, 166);
  display: inline-block;
  float: left;
  margin: 15px 0 5px 20px;
  background-color: rgb(152, 45, 166);
  color: white;
  padding: 5px 5px 5px 5px;
  font-size: 12px;
}
.clickable-area {
  cursor: pointer;
}
.cancelrow {
  margin: 0px;
}
.cancelpadding {
  padding: 5px;
}

@media (min-width: 960px) {
  .pc-ma-2 {
    margin: 16px !important;
  }
}
</style>
