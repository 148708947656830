<script setup>
import { useRouter } from "vue-router";

const router = useRouter();
const toLink = (url) => {
  router.push(url);
};
</script>

<template>
  <v-app>
    <div class="maincontainer">
      <div>
        <h2 class="mt-12 title">注文履歴</h2>
      </div>
      <div class="box clickable-area" @click="toLink('orderhistorygoods')">
        <div><img src="img/myaccount_goods.png" alt="edit" class="edit" /></div>
        <div class="info">▶︎限定グッズ注文履歴</div>
      </div>
      <div class="box clickable-area" @click="toLink('orderhistorytreca')">
        <div>
          <img src="img/myaccount_supercomment.png" alt="edit" class="edit" />
        </div>
        <div class="payment">▶︎スーパーコメント履歴</div>
      </div>
      <div class="returncontainer" @click="toLink('myaccount')">
        <v-btn variant="outlined" height="30" width="65" rounded="pill" color="black" class="btn">
          <a class="return">戻る</a>
        </v-btn>
      </div>
    </div>
  </v-app>
</template>

<style scoped>
/* .form {
  width: 100%;
} */
.maincontainer {
  margin: 0 0 50px 0;
}
.title {
  font-size: 14px;
}
.box {
  border-radius: 20px;
  border: 1px solid #000;
  background-color: white;
  width: 200px;
  height: 75px;
  padding: 0.5em 1em;
  margin: 2em 0;
  margin-left: auto;
  margin-right: auto;
  box-shadow: rgba(233, 233, 233, 0.9) 5px 5px 0px 0px;
}

.edit {
  height: 45px;
  margin: 10px 130px 0px 0;
  /* position: absolute;
  top: 0;
  left: 0.001; */
}
.info {
  font-size: 10px;
  margin: -33px -47px 0px 0;
}
.payment {
  font-size: 10px;
  margin: -33px -50px 0px 0;
}
.address {
  font-size: 10px;
  margin: -33px -30px 0px 0;
}
.order {
  font-size: 10px;
  margin: -33px 20px 0 0;
}
.logout {
  font-size: 10px;
  text-decoration: underline;
  color: rgb(165 71 71);
}
.logoutcontainer {
  margin: 8px 0 0 300px;
}
.btn {
  background-color: rgb(226, 226, 226);
  box-shadow: rgba(233, 233, 233, 0.9) 3px 3px 0px 0px;
}
.returncontainer {
  margin: 100px 0 0 0;
}
.return {
  color: rgb(142 144 147);
  font-size: 12px;
}
.clickable-area {
  cursor: pointer;
}
/*スマートフォン-----------------------------------------------------------------------------------*/
/*@media only screen and (max-width:768px){*/
@media only screen and (max-width: 1000px) {
  .login {
    color: rgb(255 255 255);
    font-size: 12px;
  }
}
</style>
