<template>
  <v-app>
    <h2 class="title mt-7">認証コードの入力</h2>
    <div v-if="isLoading" class="loading-overlay">
      <div class="loading-message">ローディング中<span class="dots">...</span></div>
    </div>
    <p class="mt-5">
      ご登録のメールアドレス宛に＜認証コード＞が送信されました。<br />届いたメールの本文に記載されている４桁の認証コードをご入力ください。
    </p>
    <div class="mt-10 mailaddress">認証コード</div>
    <v-text-field
      class="pin mt-2"
      v-model="pin"
      :rules="pinRules"
      density="compact"
      required
      @input="unshowError"
    />
    <div>
      <v-btn
        class="mt-5"
        height="35"
        width="130"
        rounded="pill"
        @click="submit"
        color="rgb(202, 68, 70)"
      >
        <a class="login">認証を完了する</a>
      </v-btn>
    </div>
    <div>
      <p class="mt-4 resend" @click="resendEmail">▶︎認証メールを再送信する</p>
    </div>
    <div v-if="flagmessage" class="textmessage mt-5">
      <p>認証コードを再送信しました。</p>
    </div>
    <div v-if="error" class="textmessage mt-5">
      <p>認証コードの再送信に失敗しました。</p>
    </div>
    <div v-if="pinerror" class="textmessage mt-5">
      <p>認証コードが間違っております。メールを確認の上、再度ご入力ください。</p>
    </div>
  </v-app>
</template>

<script setup>
import { ref } from "vue";
import { getFunctions, httpsCallable } from "firebase/functions";

const pin = ref("");
const flagmessage = ref(false);
const error = ref(false);
const pinerror = ref(false);
const isLoading = ref(false);

const pinRules = [
  (v) => !!v || "認証コードを入力してください",
  (v) => (v && v.length === 4) || "認証コードは4桁で入力してください",
];

const submit = async () => {
  isLoading.value = true;
  if (pin.value.length === 4) {
    console.log(pin.value);
    const functions = getFunctions(undefined, "asia-northeast1");
    const commonCheckPin = httpsCallable(functions, "Common_checkPin");

    try {
      const result = await commonCheckPin({ pinFromFront: pin.value });
      console.log(result.data); // 結果は `data` プロパティに格納されています
      if (result.data.text === "成功した") {
        isLoading.value = false;
        console.log("成功した");
        const routeTo = "/twofactorauthenticationdone";
        window.location.href = routeTo;
      } else if (result.data.text === "失敗した") {
        isLoading.value = false;
        flagmessage.value = false;
        pinerror.value = true;
      }
    } catch (error) {
      console.error("Error calling function:", error);
      flagmessage.value = false;
      pinerror.value = true;
      isLoading.value = false;
    }
    // window.location.href = "/login";
  }
};

// メール再送信処理の関数
const resendEmail = async () => {
  pinerror.value = false;
  isLoading.value = true;
  console.log("メールを再送信します。");
  // ここにメールを再送信するためのロジックを追加
  const functions = getFunctions(undefined, "asia-northeast1");
  const confirmMail = httpsCallable(functions, "Common_confirmMail");

  try {
    const result = await confirmMail({});
    console.log(result.data); // 結果は `data` プロパティに格納されています
    if (result.data.text === "成功した") {
      isLoading.value = false;
      flagmessage.value = true;
    } else if (result.data.text === "失敗した") {
      isLoading.value = false;
      error.value = true;
    }
  } catch (error) {
    isLoading.value = false;
    console.error("Error calling function:", error);
  }
};

const unshowError = () => {
  error.value = false;
  pinerror.value = false;
  flagmessage.value = false;
};
</script>

<style scoped>
.pin {
  width: 300px;
  margin: auto;
}
.resend {
  /* color: rgb(241, 164, 0); */
  cursor: pointer;
}
.textmessage {
  color: red;
  margin: auto;
  width: 300px;
}
.title {
  font-size: 20px;
}
.login {
  color: rgb(255 255 255);
  font-size: 12px;
}
.loading-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* 全体にオーバーレイするために fixed を使用 */
  top: 0;
  left: 0;
  width: 100%; /* 画面全体の幅 */
  height: 100%; /* 画面全体の高さ */
  background-color: rgba(0, 0, 0, 0.8); /* 半透明のグレー */
  z-index: 1000; /* 他の要素の上に表示 */
}
.loading-message {
  color: white;
  font-size: 20px;
}
.loading-circle {
  width: 50px;
  height: 50px;
}
</style>
