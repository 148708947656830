<script setup>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import { toRefs } from "vue";

const props = defineProps({
  productsData: Array,
});

const { productsData } = toRefs(props);

const onSlideChange = () => {
  console.log("Slide changed");
};
</script>

<template>
  <carousel
    :items-to-show="1"
    wrap-around
    autoplay="5000"
    pauseAutoplayOnHover="true"
    @change="onSlideChange"
  >
    <Slide v-for="slide in productsData" :key="slide">
      <div class="carousel__item">
        <img class="sliderPhoto" v-bind:src="slide.vendor_image" />
      </div>
    </Slide>

    <template #addons>
      <navigation />
      <pagination />
    </template>
  </carousel>
</template>

<style scoped>
.sliderPhoto {
  width: 100%;
}
</style>
