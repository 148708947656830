<script setup>
import { toRefs } from "vue";
const props = defineProps({
  event_title: String,
});

const { event_title } = toRefs(props);
</script>

<template>
  <h2 class="name">{{ event_title }}</h2>
</template>

<style scoped>
.name {
  font-size: 16px;
  text-align: center;
}
</style>
