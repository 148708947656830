<script setup>
import { ref } from "vue";
import BannerPicture from "/src/components/atoms/BannerPicture.vue";
import CampaignTitleBar from "/src/components/atoms/CampaignTitleBar.vue";
import ArchiveInfo from "/src/components/molecules/ArchiveInfo.vue";

import { formatDateJaFull } from "@/utils/date.js";

import db from "../main.js"; //追加
import {
  collection,
  getDocs,
  onSnapshot,
  doc,
  query,
  limit,
  orderBy,
  getDoc,
} from "firebase/firestore";
import { useRoute } from "vue-router";
// import { useRouter } from "vue-router";

const route = useRoute();
// const router = useRouter();

const event_id = route.query.event_id;
const vtuber_image = ref(null);
const event_title = ref(null);
const eventdatestart = ref(null);
const eventdateend = ref(null);
const price = ref(null);
const shippingdate = ref(null);
const nowstreaming = ref(false);
const livedatefirst = ref();
const livedatesecond = ref();
const livedatethird = ref();
const livedatefourth = ref();
const livedateend = ref();
const totallivecount = ref();
const vendorja = ref();

const getEventData = async () => {
  // const docRef = doc(db, "Goods_products", vendorname);
  onSnapshot(doc(db, "Goods_products", event_id), (doc) => {
    const data = doc.data();
    vtuber_image.value = data.vendor_image;
    event_title.value = data.eventtitle;
    vendorja.value = data.vendor_ja;
    eventdatestart.value = formatDateJaFull(data.eventdate.start.toDate());
    eventdateend.value = formatDateJaFull(data.eventdate.end.toDate());
    price.value = data.price;
    shippingdate.value = data.shipping_date;
    nowstreaming.value = data.nowstreaming;
    livedatefirst.value = data.livedate.first
      ? formatDateJaFull(data.livedate.first.toDate())
      : null;
    livedatesecond.value = data.livedate.second
      ? formatDateJaFull(data.livedate.second.toDate())
      : null;
    livedatethird.value = data.livedate.third
      ? formatDateJaFull(data.livedate.third.toDate())
      : null;
    livedatefourth.value = data.livedate.fourth
      ? formatDateJaFull(data.livedate.fourth.toDate())
      : null;
    livedateend.value = data.livedate.end ? formatDateJaFull(data.livedate.end.toDate()) : null;
    totallivecount.value = data.totallivecount;
  });
};

getEventData();

//当該ベンダーのサブコレクション取得
const goodsData = ref([]);
const getProductsData = async () => {
  const querySnapshot = await getDocs(collection(db, "Goods_products", event_id, "products"));
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    goodsData.value.push(data);
  });
};

getProductsData();

// const toMain = () => {
//   router.push(`main?event_id=${event_id}`);
//   // this.$router.push(`/itemlist?vtubername=${card.vtuber_en}`, () => {});
// };

//point呼び出し
const rankingarray = ref([]);
// const rankingno1 = ref(null);
// const rankingno2 = ref(null);
// const rankingno3 = ref(null);
const getpoint = async () => {
  const querypoint = query(
    collection(db, "Goods_ranking", event_id, "ranking"),
    limit(3),
    orderBy("points", "desc")
  );
  // , where("capital", "==", true));
  onSnapshot(querypoint, (querySnapshot) => {
    rankingarray.value = [];
    // const rankingarray = ref([]);
    if (querySnapshot.empty) {
      const rankingarray = [];
      return rankingarray;
    }
    querySnapshot.forEach((doc) => {
      const setranking = doc.data();
      rankingarray.value.push(setranking);
    });
  });
};
getpoint();

//当選確率を取得

const winningRateSsr = ref("");
const winningRateR = ref("");
const winningRateA = ref("");
const winningRateB = ref("");
const winningRateC = ref("");
const getWinningRateData = async () => {
  try {
    const docRef = doc(db, "Goods_products", event_id, "rarity", "rarity");
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      const data = docSnapshot.data();
      console.log(data);
      winningRateSsr.value = (data.ssr * 100).toFixed(2);
      winningRateR.value = (data.r * 100).toFixed(2);
      winningRateA.value = (data.a * 100).toFixed(2);
      winningRateB.value = (data.b * 100).toFixed(2);
      winningRateC.value = (data.c * 100).toFixed(2);
      console.log("ooooooo" + winningRateSsr.value);
      console.log(winningRateR.value);
    } else {
      console.log("ドキュメントが見つかりません");
    }
  } catch (error) {
    console.error("データの取得中にエラーが発生しました:", error);
  }
};

getWinningRateData();
</script>
<template>
  <v-row>
    <v-col cols="12">
      <BannerPicture :vtuber_image="vtuber_image" />
    </v-col>

    <v-col cols="12">
      <CampaignTitleBar :event_title="event_title" />
    </v-col>

    <v-col cols="12">
      <h3 class="subtitle">アーカイブ＆配信時間外購入</h3>
    </v-col>

    <v-col cols="12">
      <ArchiveInfo
        :eventdatestart="eventdatestart"
        :eventdateend="eventdateend"
        :livedatefirst="livedatefirst"
        :livedatesecond="livedatesecond"
        :livedatethird="livedatethird"
        :livedatefourth="livedatefourth"
        :livedateend="livedateend"
        :totallivecount="totallivecount"
        :vendorja="vendorja"
        :eventid="event_id"
      />
    </v-col>
  </v-row>
</template>

<style scoped>
.title {
  margin: 40px 0 30px 0;
}
.boxtitle {
  border: solid 1px rgb(218, 57, 144);
  display: inline-block;
  padding: 15px 15px 15px 15px;
  background-color: rgb(218, 57, 144);
  border-radius: 20px 20px 20px 20px;
  color: white;
  cursor: pointer;

  /* text-align: left; */
}
.boxtitlebefore {
  border: solid 1px rgb(204, 204, 204);
  display: inline-block;
  padding: 15px 15px 15px 15px;
  background-color: rgb(204, 204, 204);
  border-radius: 20px 20px 20px 20px;
  color: white;

  /* text-align: left; */
}
.attention {
  margin: 0px 5px 0px 5px;
}
.subtitle {
  font-size: 16px;
}
</style>
