<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { getFunctions, httpsCallable } from "firebase/functions";
const finishflag = ref(false);
const missfinishflag = ref(false);
const email = ref(null);
const emailRules = [
  (v) => !!v || "メールアドレスを入力してください",
  (v) => /.+@.+\..+/.test(v) || "メールアドレスを入力してください",
];
const text = ref("");
const router = useRouter();
const toLink = (url) => {
  router.push(url);
};
const toResetPassword = async () => {
  if (email.value != "undefined") {
    const functions = getFunctions();
    functions.region = "asia-northeast1"; // これを追加
    const addMessage = httpsCallable(functions, "Goods_resetpasswordemail");
    try {
      const result = await addMessage({ email: email.value });
      missfinishflag.value = false;
      finishflag.value = true;
      console.log(result);
    } catch (error) {
      missfinishflag.value = true;
      // Firebaseからのエラーをキャッチして、エラーメッセージを表示
      // errorMessage.value = error.message;
    }
  } else {
    missfinishflag.value = true;
  }
};

const deletemessage = () => {
  email.value = null;
};
</script>

<template>
  <v-app>
    <div class="maincontainer">
      <div>
        <h2 class="mt-12 title">パスワードの再設定</h2>
      </div>
      <div class="warning mt-12 ml-12 mr-12">
        ※パスワード再設定用のURLを送信いたします。ご登録いただいているメールアドレスを入力し
        「送信」ボタンを押してください。
      </div>
      <div class="mt-12">
        <v-form ref="form" v-model="valid" lazy-validation class="form">
          <div>
            <div class="mailaddress">メールアドレス</div>
            <v-text-field
              @click="deletemessage()"
              class="ml-10 mr-10"
              v-model="email"
              :rules="emailRules"
              density="compact"
              required
            />
          </div>
          <div>
            <v-btn
              class="mt-5"
              :disabled="!valid"
              height="35"
              width="100"
              rounded="pill"
              @click="toResetPassword()"
              color="rgba(89, 117, 156)"
            >
              <a class="send">送信</a>
            </v-btn>
          </div>
        </v-form>
        <div v-if="finishflag" class="warning mt-12 ml-12 mr-12">
          パスワード再設定用のメールを送信しました。<br />メールをご確認ください。
        </div>
        <div v-if="missfinishflag" class="warning mt-12 ml-12 mr-12">
          パスワード再設定用のメールの送信に失敗しました。<br />登録の正しいメールアドレスをご入力ください。。
        </div>
      </div>
      <div class="message">
        {{ text }}
      </div>
      <div class="resetpasswordcontainer">
        <a class="resetpassword" @click="toLink('login')"> ▶︎ログイン画面へ戻る </a>
      </div>
    </div>
  </v-app>
</template>

<style scoped>
.maincontainer {
  margin: 0 0 50px 0;
}
.title {
  font-size: 14px;
}
.send {
  color: rgb(255 255 255);
  font-size: 12px;
}
.resetpassword {
  font-size: 10px;
  text-decoration: underline;
}
.resetpasswordcontainer {
  margin: 45px 0 0 250px;
}
.signup {
  color: rgb(255 255 255 100%);
  font-size: 12px;
}
.mailaddress {
  font-size: 12px;
  text-align: left;
  margin-left: 40px;
}
.warning {
  font-size: 10px;
  color: rgb(165 71 71);
}
.message {
  color: red;
  font-size: 10px;
  margin: 10px 0 0 0;
}
/* .mainbody {
  min-height: 100%;
} */

/*スマートフォン-----------------------------------------------------------------------------------*/
/*@media only screen and (max-width:768px){*/
@media only screen and (max-width: 1000px) {
  .login {
    color: rgb(255 255 255);
    font-size: 12px;
  }
}
</style>
