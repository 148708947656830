<script setup>
import { ref } from "vue";
const emit = defineEmits(["changename"]);

const closedialogname = () => {
  emit("closedialogname");
};
const nickname = ref(null);
const nicknameError = ref(null);

const validateNickname = () => {
  if (nickname.value.length < 2 || nickname.value.length > 10) {
    nicknameError.value = "ユーザーネームは2文字以上10文字以下で入力してください。";
  } else {
    nicknameError.value = null;
  }
};

const changename = () => {
  if (!nicknameError.value) {
    emit("changename", nickname);
  }
};
</script>

<template div="maincontainer">
  <v-row class="dialogtitle pt-5 pb-5" height="100px" justify="center"
    >ユーザーネーム（ニックネーム）を変更</v-row
  >
  <div class="background">
    <div class="field">
      <p class="title">新しいユーザーネームを入力</p>
      <input type="text" v-model="nickname" required class="inputname" @input="validateNickname" />
      <p class="smallword">※ユーザーネームは２文字以上１０文字以内でご入力ください。</p>
      <p class="smallword error-message" v-if="nicknameError">{{ nicknameError }}</p>
    </div>

    <div class="bottonconteiner">
      <div class="">
        <v-btn class="ml-13" height="30" width="80" rounded="pill" color="rgba(226, 226, 226)">
          <a class="return" @click="closedialogname">キャンセル</a>
        </v-btn>
      </div>
      <div>
        <v-btn
          class="ml-5"
          height="30"
          width="80"
          rounded="pill"
          @click="changename()"
          color="rgba(89, 117, 156)"
        >
          <a class="addaddress">保存する</a>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<style scoped>
.field {
  margin: 40px 0 0 0;
}

.title {
  margin: 10px 0 0 70px;
  font-size: 12px;
}

.dialogtitle {
  margin: 0 0 0 0;
  /* padding: 0.5em 1em; */
  background: #fff;
  border: solid 1px;
  border-radius: 10px 10px 0 0; /*角の丸み*/
  background-color: rgb(142 144 147);
}

.background {
  background-color: white;
  width: 300px;
  border: solid 1px;
  border-radius: 0px 0px 10px 10px; /*角の丸み*/
}
.inputname {
  font-size: 10px;
  margin: 5px 0 0 70px;
  outline: 1px solid;
  padding: 5px 5px 5px 5px;
  width: 150px;
  height: 20px;
}
.bottonconteiner {
  margin: 50px 0 20px 0;
  display: flex;
}
.addaddress {
  color: rgb(255 255 255);
  font-size: 10px;
}
.return {
  color: rgb(142 144 147);
  font-size: 12px;
}

.smallword {
  font-size: 7px;
  margin: 5px 0 0 5px;
}

.error-message {
  color: red;
}
</style>
