<script setup>
import { getDoc, doc } from "firebase/firestore";
import db from "../main.js";
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router"; // 追加: useRouteのインポート

const orderDetails = ref({});

const route = useRoute(); // 追加: 現在のルートオブジェクトを取得

const orderNumber = ref();
orderNumber.value = route.query.orderNumber; // 追加: URLからorderNumberクエリパラメータを取得
const status = ref();
const totalPrice = ref();
const vendorNameja = ref();

const getOrderdetail = async () => {
  const docRef = doc(db, "Goods_supercommentorders", orderNumber.value);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    orderDetails.value = docSnap.data(); // ドキュメントのデータをorderDetailsに保存
    status.value = docSnap.data().status;
    totalPrice.value = docSnap.data().total_price;
    vendorNameja.value = docSnap.data().vendorname_ja;
  } else {
    // docSnap.data() will be undefined in this case
  }
};

getOrderdetail();

const message = ref();
const products = ref([]);
const trecaid = ref();

const orderDetailsSubfun = async () => {
  const docRef = doc(
    db,
    "Goods_supercommentorders",
    orderNumber.value,
    "orderproducts",
    "products"
  );
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    orderDetails.value = docSnap.data(); // ドキュメントのデータをorderDetailsに保存
    message.value = docSnap.data().message;
    products.value = docSnap.data().products;
    trecaid.value = docSnap.data().trecaid;
  } else {
    // docSnap.data() will be undefined in this case
  }
};

orderDetailsSubfun();

const router = useRouter();
const toLink = (url) => {
  router.push(url);
};
</script>
<template>
  <v-app>
    <div class="maincontainer">
      <div class="title-container">
        <h2 class="title">スーパーコメント履歴詳細</h2>
      </div>
      <div class="details-container">
        <p class="detail-item">注文番号：{{ orderNumber }}</p>
        <!-- <p class="detail-item">配達状況：{{ status }}</p> -->
        <p class="detail-item">請求金額：{{ totalPrice }}</p>
        <p class="detail-item">配信者名：{{ vendorNameja }}</p>
        <p class="detail-item">メッセージ:{{ message }}</p>
        <p class="detail-item">スーパーコメント金額:{{ products }}</p>
        <!-- <p class="detail-item">トレカID:{{ trecaid }}</p> -->
      </div>
    </div>

    <div class="returncontainer" @click="toLink('orderhistorytreca')">
      <v-btn variant="outlined" height="30" width="65" rounded="pill" color="black" class="btn">
        <a class="return">戻る</a>
      </v-btn>
    </div>
  </v-app>
</template>

<style scoped>
.title-container {
  margin: 50px 50px;
}

.details-container {
  text-align: left;
  margin-left: 40px;
}

.detail-item {
  margin-bottom: 20px; /* 各要素の間隔を追加 */
}

hr {
  border-top: 1px solid #000; /* 商品間の線 */
  margin-bottom: 20px; /* 商品間の線のためのスペースを確保 */
}

.returncontainer {
  margin: 30px 0 0 0;
}
.return {
  color: rgb(142 144 147);
  font-size: 12px;
}
</style>
