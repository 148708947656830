<script setup>
import { toRefs, computed } from "vue";
import { formatDateJaFull } from "@/utils/date.js";

const props = defineProps({
  liveData: Array,
});

const { liveData } = toRefs(props);

const liveproductsData = computed(() => {
  return liveData.value.filter(function (liveData) {
    return liveData.live_status === "live";
  });
});

const liveproductsDatainprogress = computed(() => {
  return liveData.value.filter(function (liveData) {
    return liveData.live_status === "inprogress";
  });
});

const toTwitter = () => {
  window.open("https://twitter.com/sukisukils");
};
</script>

<template>
  <v-row justify="center">
    <v-col v-for="(productsData, index) in liveproductsData" :key="index" cols="12" md="6" lg="4">
      <div class="maincontainer">
        <v-img class="cursor" :src="productsData.vendor_image" />

        <v-card>
          <div clsss="livecontainer">
            <div class="livestatus"><p>配信中</p></div>
            <h3 class="title">{{ productsData.eventtitle }}</h3>
            <p class="title">{{ productsData.livenumber }}</p>
            <p class="time">
              <v-icon size="20"> mdi-timer </v-icon
              >{{ formatDateJaFull(productsData.livedate.end.toDate()).replace(/:\d{2}$/, "") }}まで
            </p>
          </div>
        </v-card>
      </div>
    </v-col>

    <v-col
      v-for="(productsData, index) in liveproductsDatainprogress"
      :key="index"
      cols="12"
      md="6"
      lg="4"
    >
      <div>
        <v-img class="cursor" :src="productsData.vendor_image" />

        <v-card>
          <div clsss="livecontainer">
            <div class="livestatusinprogress"><p>配信予定</p></div>
            <h3 class="title">{{ productsData.eventtitle }}</h3>
            <p class="title">{{ productsData.livenumber }}</p>
            <p class="time">
              <v-icon size="20"> mdi-timer </v-icon
              >{{
                formatDateJaFull(productsData.livedate.start.toDate()).replace(/:\d{2}$/, "")
              }}から
            </p>
          </div>
        </v-card>
      </div>
    </v-col>
  </v-row>
  <v-row justify="center">
    <v-col cols="12" md="8" lg="6">
      <p>
        配信スケジュールのアップデート等の情報は、<span class="link-style" @click="toTwitter"
          >X（旧twitter）</span
        >も合わせてご確認ください
      </p>
    </v-col>
  </v-row>
</template>

<style scoped>
.maincontainer {
  margin: 10px 0 15px 0;
}

.livecontainer {
  border: solid 1px #454545;
}
.livestatus {
  border: solid 1px rgb(240, 172, 196);
  display: inline-block;
  float: left;
  margin: 15px 0 5px 20px;
  background-color: rgb(240, 172, 196);
  color: white;
  padding: 5px 5px 5px 5px;
  font-size: 12px;
  /* text-align: left; */
}
.title {
  clear: both;
  margin: 5px 0 5px 20px;
  text-align: left;
}
.time {
  margin: 5px 0 15px 20px;
  text-align: left;
}
.livestatusinprogress {
  border: solid 1px rgb(152, 45, 166);
  display: inline-block;
  float: left;
  margin: 15px 0 5px 20px;
  background-color: rgb(152, 45, 166);
  color: white;
  padding: 5px 5px 5px 5px;
  font-size: 12px;
}

.link-style {
  color: blue;
  cursor: pointer;
}

@media (min-width: 961px) {
  .maincontainer,
  div[v-col] {
    margin-left: 20px;
    margin-right: 20px;
  }
}
</style>
